import DetailsList from 'src/features/my-ordered-car/components/DetailsList';
import { MyOrderedCar } from 'src/features/my-ordered-car/domain/Car';
import { MinifiedDealerInfo } from 'src/features/my-ordered-car/domain/MinifiedDealerInfo';
import capitalize from 'src/utils/string/capitalize';
import formatBrandNames from 'src/utils/string/formatBrandNames';
import lang from 'utils/lang';

interface OrderSummaryProps {
    readonly dealer: MinifiedDealerInfo;
    readonly number: number | undefined;
    readonly version: number | undefined;
    readonly car: MyOrderedCar;
}

interface OrderSummaryRow {
    readonly key: string;
    readonly value: string;
}
const OrderSummary = ({ dealer, number, version, car }: OrderSummaryProps) => {
    const contractNumber =
        version == undefined || number == undefined
            ? ''
            : `${number}/${version}`;

    const summaryValues: OrderSummaryRow[] = [
        { key: 'label-dealerName', value: dealer.name },
        { key: 'label-contractNumber', value: contractNumber },
        { key: 'label-brand', value: formatBrandNames(car.brand) },
        { key: 'label-model', value: capitalize(car?.model?.text) || '' },
        { key: 'label-modelYear', value: car.model.year.toString() },
        { key: 'label-color', value: capitalize(car?.color?.text) || '' },
        {
            key: 'label-fuelType',
            value: lang[`contract_fuel_type_${car.drivetrain.engine.fuelType}`],
        },
    ];

    return <DetailsList items={summaryValues} />;
};

export default OrderSummary;
