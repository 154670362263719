import { PrimaryButton, TextContainer } from '@moller/design-system';
import {
    AddCarMethod,
    AddCarRequestRelationReason,
    CarRelationType,
} from 'external-apis/src/types/bilhold';
import HtmlReactParser from 'html-react-parser';
import { useState } from 'react';
import ConfirmWrapper from 'src/features/add-car_new/components/confirm-wrapper/ConfirmWrapper';

import userReasons from 'src/features/add-car_new/utils/userReasons';
import lang, { TextKey } from 'src/utils/lang';

interface UserProps {
    reasons: AddCarRequestRelationReason[];
    method?: AddCarMethod;
    addCarFunction: (relationType: CarRelationType) => void;
    addCarError: string;
    isLoading: boolean;
}

const User = ({
    reasons,
    method,
    addCarFunction,
    addCarError,
    isLoading,
}: UserProps) => {
    const reason = userReasons[reasons[0]];
    const [showRelationContent, setShowRelationContent] = useState(false);

    return (
        <>
            {reasons.length > 0 && (
                <TextContainer>
                    <h2>
                        {reason ? lang[reason.title] : lang.defaultReasonTitle}
                    </h2>
                    <p>
                        {reason
                            ? HtmlReactParser(lang[reason.description])
                            : lang.defaultReasonDescription}
                    </p>
                    {!showRelationContent && (
                        <PrimaryButton
                            onClick={() =>
                                reason?.continue && setShowRelationContent(true)
                            }
                        >
                            {lang[reason?.buttonText as TextKey]}
                        </PrimaryButton>
                    )}
                </TextContainer>
            )}
            {(showRelationContent || !reasons.length) && (
                <ConfirmWrapper
                    onClick={() => addCarFunction('User')}
                    isLoading={isLoading}
                    errorMessage={addCarError}
                    addCarWithCode={method == 'Code'}
                />
            )}
        </>
    );
};

export default User;
