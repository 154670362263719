import {
    AlertBanner,
    Column,
    LoadingSpinner,
    Modal,
    PrimaryButton,
    SecondaryButton,
    TextButton,
    TextContainer,
    TextField,
} from '@moller/design-system';
import React, { useState } from 'react';
import AddCarMethod from 'src/features/add-car_new/utils/AddCarMethod';
import { mapPendingCar } from 'src/features/home/utils/carCardMappings';
import { PATH_HOME } from 'src/routing/routes';
import { ContactItem } from 'src/shared/contact-item/ContactItem';
import { PendingCar } from 'src/types/PendingCar';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import {
    approveCodeErrorMessage_old,
    checkRenewError_old,
    useCodeRenewal,
    useConfirmCode_old,
    useDeleteAddCar,
} from '../../queries/useAddCarFunctions';

const ButtonContainer = styled.div`
    gap: var(--moller-spacing-xs);
    padding: var(--moller-spacing-m) 0;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    @media screen and (max-width: 480px) {
        flex-direction: column;

        & > * {
            width: auto;
        }
    }
`;

const TextFieldContainer = styled(TextContainer)`
    margin: 0 var(--moller-spacing-base);
`;

const ContactSupport = styled(Column)`
    margin: var(--moller-spacing-xs);
    gap: var(--moller-spacing-xxs);
    margin-bottom: var(--moller-spacing-xxl);
`;

const PendingCarInfoView = (car: PendingCar) => {
    const pendingCar = mapPendingCar(car);
    const [codeModalIsOpen, setModalIsOpen] = useState(false);
    const [code, setCode] = useState('');

    const closeModelAndRedirectToHome = () => {
        setModalIsOpen(false);
        window.location.href = PATH_HOME;
    };

    const {
        error: renewError,
        mutate: renewCode,
        isLoading: loadingCodeRenewal,
    } = useCodeRenewal(car.licensePlate);

    const {
        mutate: confirmCode,
        error: wrongCodeError,
        isError: wrongCode,
        reset: resetCodeError,
        isLoading: isLoadingConfirm,
    } = useConfirmCode_old(code, car.licensePlate, closeModelAndRedirectToHome);

    function handleConfirmCode() {
        if (code.length === 4) {
            // TODO: Should invalidate cache of cars, so UI is updated
            confirmCode();
        }
    }

    const { mutate: deleteAddCar } = useDeleteAddCar(
        // TODO: Should invalidate cache of cars, so UI is updated
        car.licensePlate,
        closeModelAndRedirectToHome
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (wrongCode && code.length > 1) resetCodeError();
        setCode(e.target.value);
    };

    const isManualPendingCar = car.method === AddCarMethod.MANUAL;

    return (
        <>
            <AlertBanner
                type="info"
                heading={
                    isManualPendingCar ? lang.addCarManuallyDescription : ''
                }
                message={
                    (pendingCar?.description as string) ??
                    lang.addCarManuallyInfo
                }
                action={
                    pendingCar.codeIsExpired
                        ? {
                              onClick: () => renewCode(),
                              label: lang.sendNewCode,
                          }
                        : pendingCar.showCodeInput
                        ? {
                              onClick: () => setModalIsOpen(true),
                              label: lang.fillInnCode,
                          }
                        : undefined
                }
            />
            {isManualPendingCar && (
                <ContactSupport>
                    <p>
                        Har behandlingen tatt mer enn 3 dager? Det kan skyldes
                        at vi mangler opplysninger.
                    </p>
                    <ContactItem
                        phoneLabel="Ring kundesenter"
                        phoneNumber="+4724033570"
                    />
                </ContactSupport>
            )}

            <Modal
                isOpen={codeModalIsOpen}
                handleClose={() => setModalIsOpen(false)}
                title={lang.fillInnCode}
            >
                <TextFieldContainer>
                    <p>{lang.inputCodeDescription}</p>
                    <TextField
                        label={lang.smsCode}
                        value={code}
                        onChange={handleChange}
                        type="text"
                        pattern="[0-9]*"
                        validity={
                            code.length < 1
                                ? undefined
                                : !/^\d+$/.test(code)
                                ? {
                                      message: lang.onlyNumbersCode,
                                      type: 'error',
                                  }
                                : code.length !== 4
                                ? {
                                      message: lang.invalid_one_time_code,
                                      type: 'error',
                                  }
                                : wrongCode
                                ? {
                                      message:
                                          lang[
                                              approveCodeErrorMessage_old(
                                                  wrongCodeError?.response
                                                      ?.status
                                              )
                                          ],
                                      type: 'error',
                                  }
                                : undefined
                        }
                    />
                    {renewError ? (
                        <p>{lang[checkRenewError_old(renewError.response)]}</p>
                    ) : null}
                    {loadingCodeRenewal ? <LoadingSpinner /> : null}
                </TextFieldContainer>
                <ButtonContainer>
                    <PrimaryButton
                        onClick={() => handleConfirmCode()}
                        loading={{
                            isLoading: isLoadingConfirm,
                            loadingText: 'Loading',
                        }}
                    >
                        {lang.fillInnCode}
                    </PrimaryButton>
                    <SecondaryButton onClick={() => renewCode()}>
                        {lang.sendNewCode}
                    </SecondaryButton>
                    <TextButton onClick={() => deleteAddCar()}>
                        {lang.removeRequest}
                    </TextButton>
                </ButtonContainer>
            </Modal>
        </>
    );
};

const fallback = (retry: () => void) => (
    <AlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise mer informasjon om bilen du har forsøkt å legge til. "
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const PendingCarInfo = withErrorBoundary(
    'PendingCarInfo',
    PendingCarInfoView,
    { fallback }
);
