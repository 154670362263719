export enum FuelType {
    PETROL = 'PETROL',
    DIESEL = 'DIESEL',
    ELECTRIC = 'ELECTRIC',
    FLEXIFUEL = 'FLEXIFUEL',
    GAS = 'GAS',
    HYBRID = 'HYBRID',
    PLUGIN_HYBRID = 'PLUGIN_HYBRID',
    UNKNOWN = 'UNKNOWN',
}
