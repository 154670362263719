import { useQuery } from '@tanstack/react-query';
import { ContractResponse } from 'src/features/my-ordered-car/domain/ContractResponse';
import get from 'src/utils/http/get';

export const useContract = (contractId: number | undefined) => {
    return useQuery({
        queryKey: ['carContract', contractId],
        queryFn: () =>
            get<ContractResponse>(
                `api/orderedCars/contracts/${contractId}?language=NOR`
            ).then((res) => res.data),
        enabled: !!contractId,
        staleTime: 1000 * 60 * 60,
    });
};
