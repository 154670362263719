import {
    AlertBanner,
    Column,
    LoadingSpinner,
    Modal,
    PrimaryButton,
    SecondaryButton,
    TextButton,
    TextContainer,
    TextField,
} from '@moller/design-system';
import {
    AddUserRelationError,
    RenewCodeError,
} from 'external-apis/src/types/bilhold';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddCarMethod from 'src/features/add-car_new/utils/AddCarMethod';
import { mapPendingCar } from 'src/features/home/utils/carCardMappings';
import { PATH_HOME } from 'src/routing/routes';
import { ContactItem } from 'src/shared/contact-item/ContactItem';
import { PendingCar } from 'src/types/PendingCar';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import {
    approveCodeErrorMessage,
    checkRenewError,
    useConfirmCode,
    useDeletePendingCar,
    useRenewCode,
} from '../../queries/useAddCarFunctions';

const ButtonContainer = styled.div`
    gap: var(--moller-spacing-xs);
    padding: var(--moller-spacing-m) 0;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    @media screen and (max-width: 480px) {
        flex-direction: column;

        & > * {
            width: auto;
        }
    }
`;

const TextFieldContainer = styled(TextContainer)`
    margin: 0 var(--moller-spacing-base);
`;

const ContactSupport = styled(Column)`
    margin: var(--moller-spacing-xs);
    gap: var(--moller-spacing-xxs);
    margin-bottom: var(--moller-spacing-xxl);
`;

const PendingCarInfoView = (car: PendingCar) => {
    const pendingCar = mapPendingCar(car);
    const [codeModalIsOpen, setModalIsOpen] = useState(false);
    const [code, setCode] = useState('');
    const navigate = useNavigate();

    const closeModelAndRedirectToHome = () => {
        setModalIsOpen(false);
        navigate(PATH_HOME);
    };

    const {
        error: renewError,
        mutate: renewCode,
        isLoading: loadingCodeRenewal,
        isSuccess: successfulRenewCode,
        reset: resetNewCodeMutationStatus,
    } = useRenewCode(car.licensePlate);

    const {
        mutate: confirmCode,
        error: wrongCodeError,
        reset: resetCodeError,
        isLoading: isLoadingConfirm,
    } = useConfirmCode(car.licensePlate, code, closeModelAndRedirectToHome);

    function handleConfirmCode() {
        if (code.length === 4) {
            confirmCode();
        }
    }

    const { mutate: deletePendingCarRequest } = useDeletePendingCar(
        car.licensePlate,
        closeModelAndRedirectToHome
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (wrongCodeError && code.length > 1) resetCodeError();
        setCode(e.target.value);
    };

    function handleCloseModal() {
        resetNewCodeMutationStatus();
        setModalIsOpen(false);
    }

    const isManualPendingCar = car.method === AddCarMethod.MANUAL;

    return (
        <>
            <AlertBanner
                type="info"
                heading={
                    isManualPendingCar ? lang.addCarManuallyDescription : ''
                }
                message={
                    (pendingCar?.description as string) ??
                    lang.addCarManuallyInfo
                }
                action={
                    pendingCar.codeIsExpired
                        ? {
                              onClick: () => renewCode(),
                              label: lang.sendNewCode,
                          }
                        : pendingCar.showCodeInput
                        ? {
                              onClick: () => setModalIsOpen(true),
                              label: lang.fillInnCode,
                          }
                        : undefined
                }
            />
            {isManualPendingCar && (
                <ContactSupport>
                    <p>
                        Har behandlingen tatt mer enn 3 dager? Det kan skyldes
                        at vi mangler opplysninger.
                    </p>
                    <ContactItem
                        phoneLabel="Ring kundesenter"
                        phoneNumber="+4724033570"
                    />
                </ContactSupport>
            )}

            <Modal
                isOpen={codeModalIsOpen}
                handleClose={handleCloseModal}
                title={lang.fillInnCode}
            >
                <TextFieldContainer>
                    <p>{lang.inputCodeDescription}</p>
                    <TextField
                        label={lang.smsCode}
                        value={code}
                        onChange={handleChange}
                        type="text"
                        pattern="[0-9]*"
                        validity={
                            code.length < 1
                                ? undefined
                                : !/^\d+$/.test(code)
                                ? {
                                      message: lang.onlyNumbersCode,
                                      type: 'error',
                                  }
                                : code.length !== 4
                                ? {
                                      message: lang.invalid_one_time_code,
                                      type: 'error',
                                  }
                                : wrongCodeError
                                ? {
                                      message:
                                          lang[
                                              // error må håndteres riktig i backend, det er wack på dette endepunktet nå
                                              approveCodeErrorMessage(
                                                  wrongCodeError?.message as AddUserRelationError,
                                                  wrongCodeError?.response
                                                      ?.status
                                              )
                                          ],
                                      type: 'error',
                                  }
                                : undefined
                        }
                    />
                    {renewError && (
                        <p>
                            {
                                lang[
                                    checkRenewError(
                                        renewError?.message as RenewCodeError,
                                        renewError.response?.status
                                    )
                                ]
                            }
                        </p>
                    )}
                    {loadingCodeRenewal && <LoadingSpinner />}
                </TextFieldContainer>
                <ButtonContainer>
                    <PrimaryButton
                        onClick={handleConfirmCode}
                        loading={{
                            isLoading: isLoadingConfirm,
                            loadingText: 'Loading',
                        }}
                        disabled={code.length !== 4}
                    >
                        {lang.confirmCode}
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={() => renewCode()}
                        disabled={successfulRenewCode}
                    >
                        {lang.sendNewCode}
                    </SecondaryButton>
                    <TextButton onClick={() => deletePendingCarRequest()}>
                        {lang.removeRequest}
                    </TextButton>
                </ButtonContainer>
                {successfulRenewCode && <p>Sendte kode til eier.</p>}
            </Modal>
        </>
    );
};

const fallback = (retry: () => void) => (
    <AlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise mer informasjon om bilen du har forsøkt å legge til. "
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const PendingCarInformation = withErrorBoundary(
    'PendingCarInfo',
    PendingCarInfoView,
    { fallback }
);
