import AddCarMethod from 'src/features/add-car_new/utils/AddCarMethod';
import lang from 'utils/lang';
import { PendingCar, SystemValidation } from '../../../types/PendingCar';
import formatLicensePlate from '../../../utils/string/formatLicensePlate';

const codeDescription = {
    VALIDATION_ACTIVE: 'pendingCarInfoCode',
    VALIDATION_PENDING: 'pendingCarInfoCodePending',
    VALIDATION_REJECTED: 'pendingCarInfoCodeRejected',
} as const;

interface CarCardData {
    title: string;
    description?: string | JSX.Element | JSX.Element[];
    isPending?: boolean;
    isCancelable?: boolean;
    codeIsExpired?: boolean;
    showCodeInput?: boolean;
    registrationNumber?: string;
}

export const mapPendingCar = (pendingCar: PendingCar): CarCardData => ({
    title: formatLicensePlate(pendingCar.licensePlate),
    registrationNumber: pendingCar.licensePlate,
    description:
        pendingCar.method === AddCarMethod.CODE
            ? pendingCar.systemValidation ===
              SystemValidation.VALIDATION_REJECTED
                ? lang.pendingCarInfoCodeRejected
                : pendingCar.codeExpired
                ? lang.pendingCarInfoCodeExpired
                : lang[codeDescription[pendingCar.systemValidation]]
            : pendingCar.method === AddCarMethod.MANUAL
            ? lang.addCarManuallyInfo
            : undefined,
    isPending: true,
    isCancelable:
        pendingCar.systemValidation === SystemValidation.VALIDATION_ACTIVE,
    codeIsExpired:
        pendingCar.systemValidation === SystemValidation.VALIDATION_REJECTED
            ? false
            : pendingCar.codeExpired,
    showCodeInput:
        !pendingCar.codeExpired &&
        pendingCar.systemValidation === SystemValidation.VALIDATION_ACTIVE,
});
