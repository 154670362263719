import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useFetchersContext } from 'external-apis';
import {
    AddCarRelationBody,
    AddCarRelationFeasibilityResult,
    AddUserRelationError,
    RenewCodeError,
} from 'external-apis/src/types/bilhold';
import httpStatus from 'http-status';
import { useNavigate } from 'react-router-dom';
import isRegNoValid from 'src/features/add-car_new/utils/isRegNoValid';
import AddCarMethod from 'src/features/add-car_old/utils/AddCarMethod';
import { PATH_HOME } from 'src/routing/routes';
import Brand from 'src/types/Brand';
import CarRelation from 'src/utils/enums/CarRelation';
import { ADD_CAR_V2 } from 'src/utils/http/endpoints';
import get from 'src/utils/http/get';
import del from 'src/utils/http/httpDelete';
import post from 'src/utils/http/post';
import put from 'src/utils/http/put';
import { useCarSwiperIndex } from '../store/useCarSwiperIndex';
import { carProfileQueryKey, fetchAndSortCars } from './useCarProfile';

export function approveCodeErrorMessage_old(statusCode?: number) {
    switch (statusCode) {
        case 400:
            return 'general_error_contact_support';
        case 403:
            return 'approveCodeHasExpired';
        case 404:
            return 'approveCodeBadRequest';
        case 429:
            return 'approveCodeTooManyRequests';
        default:
            return 'general_error_contact_support';
    }
}

export function approveCodeErrorMessage(
    errorReason?: AddUserRelationError,
    statusCode?: number
) {
    if (statusCode && statusCode !== 200) {
        return 'general_error_contact_support';
    }

    switch (errorReason) {
        case 'InvalidCode':
            return 'approveCodeBadRequest';
        case 'RequestHasExpired':
            return 'approveCodeHasExpired';
        case 'MaxAttemptsReached':
            return 'approveCodeTooManyRequests';
        case 'NoPendingRequest':
        case 'VehicleNotFound':
        default:
            return 'general_error_contact_support';
    }
}

export const useConfirmCode_old = (
    code: string,
    licencePlate: string,
    successCallback: () => void
) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>>({
        mutationKey: ['codeConfimation', licencePlate],
        mutationFn: () =>
            put(`api/v1/add-car/${licencePlate}/approve`, {
                code,
            }),
        onSuccess: () => successCallback(),
    });
};

export const useDeleteAddCar = (
    licencePlate: string,
    successCallback: () => void
) => {
    return useMutation({
        mutationKey: ['deleteAddCar', licencePlate],
        mutationFn: () => del(`api/v1/add-car/${licencePlate}`),
        onSuccess: () => successCallback(),
    });
};

export interface ErrorResponseData {
    errors?: Messages[];
    httpCode: number;
}

interface Messages {
    message:
        | 'AWAIT_RETRY'
        | 'EXCEEDED_DAILY_REQUESTS'
        | 'EXCEEDED_TOTAL_REQUESTS';
}

export function checkRenewError_old(
    errorResponse?: AxiosResponse<ErrorResponseData>
) {
    const responseData = errorResponse?.data;

    if (
        !(errorResponse?.status === httpStatus.TOO_MANY_REQUESTS) &&
        !responseData?.errors?.length
    ) {
        return 'general_error_contact_support';
    }

    const firstError =
        responseData?.errors && responseData.errors.length > 0
            ? responseData?.errors[0].message
            : '';

    switch (firstError) {
        case 'AWAIT_RETRY':
            return 'renewCodeAwaitRetry';
        case 'EXCEEDED_DAILY_REQUESTS':
            return 'renewCodeExceededDailyTries';
        case 'EXCEEDED_TOTAL_REQUESTS':
            return 'renewCodeExceededTotalTries';
        default:
            return 'general_error_contact_support';
    }
}

export function checkRenewError(
    errorReason?: RenewCodeError,
    statusCode?: number
) {
    if (statusCode && statusCode !== 200) {
        return 'general_error_contact_support';
    }

    switch (errorReason) {
        case 'TooManyRequests':
            return 'renewCodeExceededDailyTries';
        case 'PendingUserRelationRequestNotFound':
        case 'VehicleNotFound':
        default:
            return 'general_error_contact_support';
    }
}

export const useCodeRenewal = (licencePlate: string) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>>({
        mutationKey: ['codeRenewal', licencePlate],
        mutationFn: () => put(`api/v1/add-car/${licencePlate}/renew`),
    });
};

interface AddCarPostInterface {
    registrationNumber: string;
    vin: string;
    relationType: CarRelation;
    leaser?:
        | string
        | {
              organisationNumber?: string;
          };
    companyCar?:
        | string
        | null
        | {
              company: {
                  name: string | undefined;
                  electronicAddress: string | undefined;
              };
          };
}

export const useAddCar_old = (
    onSuccess: () => void,
    setError: (value: string) => void
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (addCarObject: AddCarPostInterface) =>
            post(ADD_CAR_V2, addCarObject),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });
            onSuccess();
        },
        onError: () => {
            setError('general_error_contact_support');
        },
    });
};

export type AddCarRelationReason =
    | 'RELATION_ALREADY_EXISTING_PRIVATE'
    | 'RELATION_ALREADY_EXISTING_ORGANIZATION'
    | 'RELATION_ALREADY_EXISTING_ORGANIZATION_EXT'
    | 'RELATION_ALREADY_EXISTING'
    | 'OWNER_NOT_EXISTING_IN_MVR'
    | 'PROPRIETOR_NOT_EXISTING'
    | 'PROPRIETOR_NOT_VALID'
    | 'PROPRIETOR_MOBILE_NOT_VALID'
    | 'PROPRIETOR_EXCLUDED'
    | 'PROPRIETOR_NOT_VALID_OWNER'
    | 'PROPRIETOR_NOT_VALID_LEASER';

export interface AddCarRelation {
    type: CarRelation;
    method: AddCarMethod;
    reasons: AddCarRelationReason[];
    proprietorType?: CarRelation;
}
export interface AddCarSearchResponse {
    brand: Brand;
    carModel: string;
    vin: string;
    registrationNumber: string;
    relationTypes: AddCarRelation[];
}

const RequestCarErrorMessage = {
    PENDING: 'addCarPendingRequest',
    ALREADY_ADDED: 'addCarAlreadyAdded',
    UNKNOWN_BRAND: 'addCarUnknownBrand',
    NOT_FOUND: 'addCarNotFound',
    GENERAL_ERROR: 'general_error_contact_support',
};

export interface SearchCarErrorResponseData {
    errors?: SearchCarMessage[];
    httpCode?: number;
    httpDescription?: string;
}

interface SearchCarMessage {
    message:
        | 'PENDING'
        | 'ALREADY_ADDED'
        | 'UNKNOWN_BRAND'
        | 'NOT_FOUND'
        | 'GENERAL_ERROR';
}

export const getSearchCar = (
    licensePlate: string,
    setSearchCarData: (data: AddCarSearchResponse) => void,
    setSearchCarError: (errorKey: string) => void
) => {
    const request = get<AddCarSearchResponse>(
        `api/v1/add-car/${licensePlate}/request`
    );
    return request
        .then((res) => setSearchCarData(res.data))
        .catch((error: AxiosError<SearchCarErrorResponseData>) => {
            const errorMessage = error.response?.data?.errors?.[0]?.message;
            if (Number(error.response?.data?.httpCode) === httpStatus.NOT_FOUND)
                setSearchCarError(RequestCarErrorMessage.NOT_FOUND);
            else if (error?.response?.data?.httpDescription && errorMessage)
                setSearchCarError(RequestCarErrorMessage[errorMessage]);
            else setSearchCarError(RequestCarErrorMessage.GENERAL_ERROR);
        });
};

//------------------ NEW ------------------

const useAddCarFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{RegistrationNumber}/relations')
        .method('post')
        .create();
};

interface AddCarMutationInterface {
    registrationNumber: string;
    body: AddCarRelationBody;
}

export const useAddCar = () => {
    const postAddCar = useAddCarFetcher();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const setCarSwiperIndex = useCarSwiperIndex(
        (state) => state.setCarSwiperIndex
    );

    return useMutation({
        mutationFn: async (x: AddCarMutationInterface) => {
            const response = await postAddCar({
                RegistrationNumber: x.registrationNumber,
                type: x.body.type,
                organizationNumber: x.body.organizationNumber,
            });

            return response.data;
        },

        onSuccess: async (_, variables) => {
            await queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });

            const carsList = await queryClient.fetchQuery(
                [carProfileQueryKey],
                fetchAndSortCars
            );

            // Cars are sorted as existing, ordered and pending
            const existingCarsLength = carsList.existingCars.length;
            const orderedCarsLength = carsList.orderedCars.length;

            const addedCarInPendingListIndex = carsList.pendingCars.findIndex(
                (car) => car.licensePlate === variables.registrationNumber
            );

            const addedCarInCarListIndex =
                existingCarsLength +
                orderedCarsLength +
                addedCarInPendingListIndex;

            setCarSwiperIndex(addedCarInCarListIndex);

            navigate(PATH_HOME);
        },
    });
};

// Add car request to check the feasibility of whether this car is possible to add for this user
const useAddCarRequestFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/request')
        .method('get')
        .create();
};

export const useAddCarRequestFeasibility = (registrationNumber: string) => {
    const addCarRequest = useAddCarRequestFetcher();

    return useQuery<AddCarRelationFeasibilityResult>({
        queryKey: ['addCarFeasibility', registrationNumber],
        queryFn: () =>
            addCarRequest({ RegistrationNumber: registrationNumber }).then(
                (x) => x.data
            ),
        enabled: isRegNoValid(registrationNumber),
    });
};

const useRenewCodeFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/renew')
        .method('put')
        .create();
};

interface RenewCodeErrorResponse {
    message?: RenewCodeError;
    response?: ErrorStatus;
}

export const useRenewCode = (registrationNumber: string) => {
    const renewCode = useRenewCodeFetcher();
    return useMutation<unknown, RenewCodeErrorResponse>({
        mutationFn: async () => {
            const response = await renewCode({
                RegistrationNumber: registrationNumber,
            });

            if (!response.data.success) {
                if (response.data.error) {
                    throw new Error(response.data.error);
                }
            }

            return response.data;
        },
    });
};

const useConfirmCodeFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/approve')
        .method('put')
        .create();
};

interface ErrorStatus {
    status?: number;
}

interface ConfirmCodeError {
    message?: AddUserRelationError;
    response?: ErrorStatus;
}

export const useConfirmCode = (
    registrationNumber: string,
    code: string,
    onSuccess: () => void
) => {
    const confirmCode = useConfirmCodeFetcher();
    const queryClient = useQueryClient();
    const setCarSwiperIndex = useCarSwiperIndex(
        (state) => state.setCarSwiperIndex
    );

    return useMutation<unknown, ConfirmCodeError>({
        mutationFn: async () => {
            const response = await confirmCode({
                RegistrationNumber: registrationNumber,
                value: code,
            });

            if (!response.data.isSuccess) {
                if (response.data.errorReason) {
                    throw new Error(response.data.errorReason);
                }
            }

            return response.data;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });

            const carsList = await queryClient.fetchQuery(
                [carProfileQueryKey],
                fetchAndSortCars
            );

            const addedCarInExistingListIndex = carsList.existingCars.findIndex(
                (car) => car.licensePlate === registrationNumber
            );

            setCarSwiperIndex(addedCarInExistingListIndex);

            onSuccess();
        },
    });
};

const useDeleteCodeFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}')
        .method('delete')
        .create();
};

export const useDeletePendingCar = (
    registrationNumber: string,
    onSuccess: () => void
) => {
    const deleteCode = useDeleteCodeFetcher();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () =>
            deleteCode({ RegistrationNumber: registrationNumber }).then(
                (x) => x.data
            ),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: [carProfileQueryKey],
            });
            onSuccess();
        },
    });
};
