import { LabelText, TextContainer, TextField } from '@moller/design-system';
import {
    AddCarRequestRelationReason,
    CarRelationType,
} from 'external-apis/src/types/bilhold';
import HtmlReactParser from 'html-react-parser';
import { useState } from 'react';
import ConfirmWrapper from 'src/features/add-car_new/components/confirm-wrapper/ConfirmWrapper';
import companyReasons from 'src/features/add-car_new/utils/companyReasons';
import searchCarState from 'src/features/add-car_new/utils/searchCarState';
import lang, { TextKey } from 'src/utils/lang';
import { VALID_ORGANISATION_NUMBER } from 'src/utils/string/regex';

interface CompanyProps {
    reasons: AddCarRequestRelationReason[];
    addCarFunction: (
        relationType: CarRelationType,
        organizationNumber: string
    ) => void;
    addCarError: string;
    isLoading: boolean;
    setErrorMessage: (value: string) => void;
}

const Company = ({
    reasons,
    addCarFunction,
    addCarError,
    setErrorMessage,
    isLoading,
}: CompanyProps) => {
    const reason = companyReasons[reasons[0]];
    const [organisationNumber, setOrganisationNumber] = useState('');
    const [orgNoError, setOrgNoError] = useState('');

    const validOrganisationNumber =
        VALID_ORGANISATION_NUMBER.test(organisationNumber);

    return (
        <>
            {reasons.length > 0 ? (
                <TextContainer>
                    <h2>
                        {reason ? lang[reason.title] : lang.defaultReasonTitle}
                    </h2>
                    <p>
                        {reason
                            ? HtmlReactParser(lang[reason.description])
                            : lang.defaultReasonDescription}
                    </p>
                </TextContainer>
            ) : (
                <>
                    <TextField
                        className="add-car__input"
                        onChange={(e) => {
                            setOrganisationNumber(e.target.value?.trim());
                            setErrorMessage('');
                            e.target.value?.trim().length > 9
                                ? setOrgNoError('orgNoGeneralError')
                                : setOrgNoError('');
                        }}
                        onBlur={() =>
                            !validOrganisationNumber &&
                            organisationNumber.length >= 1 &&
                            setOrgNoError('orgNoGeneralError')
                        }
                        label={lang.companyCarOrgNo}
                        value={organisationNumber}
                        autoFocus
                        required
                        validity={{
                            type: searchCarState(
                                orgNoError,
                                validOrganisationNumber
                            ),
                            message: orgNoError
                                ? lang[orgNoError as TextKey]
                                : lang.companyCarOrgNo,
                        }}
                    />
                    <LabelText>
                        <p>{HtmlReactParser(lang.findOrgNo)}</p>
                    </LabelText>
                    <ConfirmWrapper
                        onClick={() =>
                            addCarFunction('CompanyCar', organisationNumber)
                        }
                        isLoading={isLoading}
                        errorMessage={addCarError}
                        disabled={!validOrganisationNumber}
                    />
                </>
            )}
        </>
    );
};

export default Company;
