import { useQuery } from '@tanstack/react-query';
import { ContractResponse } from 'src/features/my-ordered-car/domain/ContractResponse';
import get from 'src/utils/http/get';

export const useGetContract = (id: string | undefined, shouldFetch = true) => {
    return useQuery({
        queryKey: ['contract', id],
        queryFn: () =>
            get<ContractResponse>(
                `api/orderedCars/contracts/${id}?language=NOR`
            ).then((res) => {
                return res.data;
            }),
        enabled: shouldFetch,
    });
};
