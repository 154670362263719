import { Subtitle } from '@moller/design-system';
import { Divider } from '@mui/material';
import DealerContact from 'src/features/my-ordered-car/components/DealerContact';
import { MinifiedDealerInfo } from 'src/features/my-ordered-car/domain/MinifiedDealerInfo';
import { Seller } from 'src/features/my-ordered-car/domain/Seller';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';

interface DealerSectionProps {
    readonly dealer: MinifiedDealerInfo | undefined;
    readonly seller?: Seller;
}

const FutureCarPageContainer = styled.div`
    margin-top: var(--moller-spacing-m);
`;
const SubTitleWrapper = styled.div`
    margin-top: var(--moller-spacing-s);
`;
const StyledDivider = styled(Divider)`
    padding-top: var(--moller-spacing-s);
    border-color: var(--moller-color-outline) !important;
`;

const DealerSection = ({ seller, dealer }: DealerSectionProps) => {
    return (
        <FutureCarPageContainer>
            <Subtitle>
                <b>{lang.myDealer}</b>
            </Subtitle>
            <div>
                {seller && (
                    <div {...ddRumPrivacySetting('mask')}>
                        <SubTitleWrapper>
                            <b>{seller.name}</b>
                        </SubTitleWrapper>
                        <DealerContact
                            phoneNumber={seller.phoneNumber}
                            email={seller.sellerEmail}
                        />
                        <StyledDivider />
                    </div>
                )}
                {dealer && (
                    <>
                        <SubTitleWrapper>
                            <b>{dealer.name}</b>
                        </SubTitleWrapper>
                        <DealerContact
                            dealerName={dealer.name}
                            address={dealer.location.address}
                            postalArea={dealer.location.postalArea}
                            phoneNumber={dealer.phoneNumber}
                            email={dealer.email}
                        />
                    </>
                )}
            </div>
        </FutureCarPageContainer>
    );
};

export default DealerSection;
