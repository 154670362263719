import { ReasonInfos } from './types';

const reasons = {
    RELATION_ALREADY_EXISTING: {
        title: 'leaserRelationAlreadyExistingTitle',
        description: 'leaserRelationAlreadyExistingDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_EXISTING: {
        title: 'leaserProprietorNotExistingTitle',
        description: 'leaserProprietorNotExistingDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_VALID: {
        title: 'leaserProprietorNotValidTitle',
        description: 'leaserProprietorNotValidDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const leaserReasons: ReasonInfos<typeof reasons> = reasons;

export default leaserReasons;
