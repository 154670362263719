import { AlertBanner } from '@moller/design-system';
import { Product } from 'src/features/home/utils/productType';
import { Contract } from 'src/features/my-ordered-car/domain/Contract';

interface ErrorMessageWithManualEmailProps {
    orderedCarDealerEmail: string;
    product: Product;
    contract: Contract;
    articleNumber: string;
    userFirstName: string;
}

export const ErrorMessageWithManualEmail = ({
    orderedCarDealerEmail,
    product,
    contract,
    articleNumber,
    userFirstName,
}: ErrorMessageWithManualEmailProps) => {
    const sendOrderEmailManually = () => {
        const email = encodeURIComponent(orderedCarDealerEmail);
        const subject = encodeURIComponent(
            `Bestilling av produkt ${product.title}`
        );
        const body = encodeURIComponent(
            `Hei, jeg ønsker å bestille produktet ${
                product.title
            } (artikkelnummer: ${articleNumber}) til min bil.
    
            Bil og kontraktsinformasjon:
            Merke: ${contract?.car?.brand}
            Modell: ${contract?.car?.model?.text}
            Kontraktsnummer: ${`${contract.number}/${contract.version}`}
    
            Hilsen
            ${userFirstName}
            `.replace(/  +/g, ' ')
        );

        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    return (
        <AlertBanner
            type={'error'}
            message={`Det har dessverre oppstått en feil. Trykk på knappen under for å åpne e-postklienten din med ferdigutfylt informasjon for bestillingen.`}
            action={{
                label: 'Send e-post',
                onClick: sendOrderEmailManually,
            }}
        />
    );
};
