import { useQuery } from '@tanstack/react-query';
import Brand from 'src/types/Brand';
import Car from 'src/types/Car';
import get from 'src/utils/http/get';

export interface RecallProps {
    carName: string;
    vin: string;
    licensePlate: string;
    recallCode: string;
}

export function validateRecalls(
    car: Car,
    recall: RecallProps | undefined
): boolean {
    if (recall === undefined) {
        return false;
    } else if (car.brand === Brand.SEAT) {
        return recall.recallCode === '48R8';
    } else if (car.brand === Brand.VW) {
        return recall.recallCode === '48R7';
    } else if (car.brand === Brand.SKODA) {
        return recall.recallCode === '48S2';
    } else if (car.brand === Brand.AUDI) {
        return recall.recallCode === '93V2' || recall.recallCode === '93U9';
    }
    return false;
}

export const useRecalls = () => {
    return useQuery({
        queryKey: ['recalls'],
        queryFn: () =>
            get<RecallProps[] | []>(`api/recalls`).then((res) => res.data),
        staleTime: 1000 * 60 * 60,
    });
};
