import AddCarMethod from 'src/features/add-car_new/utils/AddCarMethod';
import Brand from './Brand';

export interface PendingCar {
    brand: Brand;
    licensePlate: string;
    status: PendingStatus;
    systemValidation: SystemValidation;
    codeExpired: boolean;
    method: AddCarMethod;
    originBilholdApi: boolean | null;
}

export enum PendingStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    REJECTED = 'REJECTED',
}

export enum SystemValidation {
    VALIDATION_ACTIVE = 'VALIDATION_ACTIVE',
    VALIDATION_PENDING = 'VALIDATION_PENDING',
    VALIDATION_REJECTED = 'VALIDATION_REJECTED',
}
