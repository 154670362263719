import { ReasonInfos } from './types';

const reasons = {
    PROPRIETOR_MOBILE_NOT_VALID: {
        title: 'userProprietorMobileNotValidTitle',
        description: 'userProprietorMobileNotValidDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_EXISTING: {
        title: 'userProprietorNotExistingTitle',
        description: 'userProprietorNotExistingDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_VALID: {
        title: 'userProprietorNotValidTitle',
        description: 'userProprietorNotValidDescription',
        buttonText: 'iUnderstand',
    },
    TOTAL_CODE_REQUEST_LIMIT_EXCEEDED: {
        title: 'userLimitExceededTitle',
        description: 'userLimitExceededDescription',
        buttonText: 'continue',
        continue: true,
    },
    PROPRIETOR_EXCLUDED: {
        title: 'userProprietorExcludedTitle',
        description: 'userProprietorExcludedDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const userReasons: ReasonInfos<typeof reasons> = reasons;

export default userReasons;
