import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import Brand from 'src/types/Brand';
import { isMobileApp } from 'src/utils/mobile';

export const renderLogoSize = (brandLogoName: LogoBrand) => {
    switch (brandLogoName) {
        case 'vw':
        case 'cupra':
            return 'xxl';
        default:
            if (isMobileApp()) {
                return 'm';
            }
            return 'l';
    }
};

export const brandToLogoName = (carTheme: Brand | undefined) => {
    if (!carTheme) {
        return;
    }
    if (carTheme === Brand.NYTTE) {
        return Brand.VW.toLocaleLowerCase();
    }
    return carTheme?.toLocaleLowerCase();
};
