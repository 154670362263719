import {
    AddCarRelationFeasibilityResult,
    CarRelationType,
} from 'external-apis/src/types/bilhold';
import { useEffect, useState } from 'react';
import Company from 'src/features/add-car_new/components/company/Company';
import Leaser from 'src/features/add-car_new/components/leaser/Leaser';
import Owner from 'src/features/add-car_new/components/owner/Owner';
import User from 'src/features/add-car_new/components/user/User';
import { useAddCar } from 'src/features/home/queries/useAddCarFunctions';

interface CarRelationContentProps {
    carRelation?: CarRelationType;
    registrationNumber: string;
    addCarRelationResult: AddCarRelationFeasibilityResult;
}

interface CustomError {
    status?: number;
}

export const CarRelationContent = ({
    carRelation,
    registrationNumber,
    addCarRelationResult,
}: CarRelationContentProps) => {
    const [errorMessage, setErrorMessage] = useState('');

    const { mutate: addCarMutate, isLoading, error } = useAddCar();

    const addCar = (
        relationType: CarRelationType,
        organizationNumber?: string
    ) => {
        addCarMutate({
            registrationNumber: registrationNumber,
            body: {
                type: relationType,
                organizationNumber: organizationNumber,
            },
        });
    };

    useEffect(() => {
        const typedError = error as CustomError;

        if (typedError) {
            if (typedError?.status === 409) {
                setErrorMessage('pendingCarExists');
            } else {
                setErrorMessage('general_error_contact_support');
            }
        } else {
            setErrorMessage('');
        }
    }, [error]);

    const chosenRelation =
        addCarRelationResult.relationDetails?.relationTypes.find(
            (relation) => relation.type === carRelation
        );

    switch (carRelation) {
        case 'Owner':
            return (
                <Owner
                    reasons={chosenRelation?.reasons ?? []}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            );
        case 'Leaser':
            return (
                <Leaser
                    reasons={chosenRelation?.reasons ?? []}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                    setErrorMessage={setErrorMessage}
                />
            );
        case 'User':
            return (
                <User
                    reasons={chosenRelation?.reasons ?? []}
                    method={chosenRelation?.method}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                />
            );
        case 'CompanyCar':
            return (
                <Company
                    reasons={chosenRelation?.reasons ?? []}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                    setErrorMessage={setErrorMessage}
                />
            );

        default:
            return <></>;
    }
};
