import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { Recall } from 'external-apis/src/types/carcare';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';

const useFindRecalls = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.carcare.fetcher.path('/api/recalls').method('get').create();
};

export function useGetRecalls({ vin }: { vin?: string }) {
    const [lc] = useLanguageContext();
    const findRecalls = useFindRecalls();

    /* the carcare endpoint requires authentication
     *  remove use of authenticated when this is changed
     * */
    return useQuery<Recall[], Error>({
        queryKey: ['recalls', { vin }],
        staleTime: STALE_TIME.hour,
        queryFn: () => getRecalls(vin ?? '', lc, findRecalls),
        enabled: !!vin,
    });
}

async function getRecalls(
    vin: string,
    lc: LanguageContextType,
    findRecalls: ReturnType<typeof useFindRecalls>
) {
    try {
        const result = await findRecalls({ vin });
        return result.data;
    } catch (e) {
        if (e instanceof findRecalls.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_dealers_default,
                e,
                e.status
            );
        }
        throw e;
    }
}
