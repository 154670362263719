import { Brand } from 'external-apis/src/types/bilhold';
import toLowerCaseWithCapitalize from './toLowerCaseWithCapitalize';

const convertBrand = (brand?: Brand) => {
    if (!brand) return 'Ukjent';

    switch (brand) {
        case 'VolkswagenCommercialVehicles':
            return 'Volkswagen';
        case 'Cupra':
            return 'CUPRA';
        case 'Skoda':
            return 'Škoda';
        case 'Other':
            return 'Ukjent';
        default:
            return brand;
    }
};

export const formatCarName = (brand?: Brand, modelName?: string | null) => {
    return `${convertBrand(brand)} ${toLowerCaseWithCapitalize(
        modelName ?? ''
    )}`;
};
