import { Capacitor } from '@capacitor/core';
import {
    AlertBanner,
    Column,
    Headline,
    PrimaryButton,
    Row,
    TextField,
} from '@moller/design-system';
import { adobeEvent } from 'adobe-utils';
import { useEffect, useState } from 'react';
import { checkTextFieldValidity } from 'src/features/user-profile/user-settings/utils/checkProfileError';
import { UserInfo } from 'src/utils/auth/mapMdmUserInfo';
import {
    ONLY_NUMBERS,
    VALID_EMAIL,
    VALID_NAME,
    VALID_ZIP_INPUT,
} from 'src/utils/string/regex';
import { styled } from 'styled-components';
import lang, { TextKey } from 'utils/lang';
import { TermsOfUse } from '../components/TermsOfUse';
import { PostUserInfo } from '../utils/postCreateUser';
import { useCityFromZipCode } from '../utils/useCityFromZipCode';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    gap: 12px;
`;

const StyledForm = styled.form`
    margin-top: var(--moller-spacing-m);
    gap: var(--moller-spacing-base);
`;

const DoubleInput = styled(Row)`
    max-width: 100%;
    gap: var(--moller-spacing-xs);
    span {
        min-width: 40%;
    }
`;

const SubmitButton = styled(PrimaryButton)`
    width: 100%;
    margin: 12px 0;
`;
interface CreateNewUserFormProps {
    readonly result: UserInfo;
    readonly postUser: (postUserData: PostUserInfo) => void;
    readonly postError: string;
    readonly login: (password: string) => void;
    readonly phoneNumber: string;
    readonly isLoading: boolean;
}

const generatePassword = (): string => {
    const length = 10;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let password = '';
    for (let i = 0; i < length; i++) {
        password += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return password;
};

const CreateNewUserForm = ({
    result = {},
    postUser,
    postError,
    login,
    isLoading,
    phoneNumber,
}: CreateNewUserFormProps) => {
    const autofill = (fieldName: string | undefined) => fieldName ?? '';
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState(autofill(result.firstName));
    const [lastName, setLastName] = useState(autofill(result.lastName));
    const [addressLine, setAddressLine] = useState(
        autofill(result.addressLine)
    );
    const [zipCode, setZipCode] = useState(autofill(result.zipCode));
    const [cityFromUser, setCityFromUser] = useState('');
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [password] = useState(generatePassword());
    const [infoValidated, setInfoValidated] = useState(false);

    const { city, disableUserInput, errorMessage } =
        useCityFromZipCode(zipCode);

    const validEmail = VALID_EMAIL.test(email);
    const validFirstName = VALID_NAME.test(firstName);
    const validLastName = VALID_NAME.test(lastName);
    const validZipCode = VALID_ZIP_INPUT.test(zipCode);
    const noValidationErrors =
        validEmail && validLastName && validFirstName && validZipCode;

    useEffect(() => {
        if (
            !infoValidated &&
            validEmail &&
            validFirstName &&
            validLastName &&
            validZipCode &&
            email &&
            firstName &&
            lastName &&
            addressLine &&
            zipCode
        ) {
            adobeEvent.push(
                'formStep',
                {
                    formName: 'Create new user',
                    stepName: 'Personal info validated',
                    stepNumber: 2,
                    formId: phoneNumber,
                },
                Capacitor.getPlatform()
            );
            setInfoValidated(true);
        }
    }, [
        email,
        firstName,
        lastName,
        addressLine,
        zipCode,
        infoValidated,
        validEmail,
        validFirstName,
        validLastName,
        validZipCode,
        phoneNumber,
    ]);

    const onSubmit = () => {
        if (postError === 'login_error') {
            login(password);
        } else
            postUser({
                password,
                userInfo: {
                    addressLine,
                    city,
                    email,
                    firstName,
                    lastName,
                    zipCode,
                },
            });
    };

    const isNotValidForm = !(
        acceptedTerms &&
        email &&
        firstName &&
        lastName &&
        addressLine &&
        zipCode &&
        password &&
        noValidationErrors &&
        (city || cityFromUser)
    );

    return (
        <Container>
            <div>
                <Headline $as="h3">{lang.register_new_user}</Headline>
                {/* Should be replaced with form provider from design system`` */}
                <StyledForm
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit();
                    }}
                >
                    <Column>
                        <TextField
                            onChange={(e) => {
                                setEmail(e.target.value.trim());
                            }}
                            label={lang.email}
                            value={email}
                            autoFocus
                            validity={checkTextFieldValidity('email', email)}
                            required
                        />
                        <TextField
                            onChange={(e) => {
                                setFirstName(e.target.value.trimStart());
                            }}
                            label={lang.firstName}
                            value={firstName}
                            validity={checkTextFieldValidity('name', firstName)}
                            required
                        />
                        <TextField
                            onChange={(e) => {
                                setLastName(e.target.value.trimStart());
                            }}
                            label={lang.last_name}
                            value={lastName}
                            validity={checkTextFieldValidity('name', lastName)}
                            required
                        />
                        <TextField
                            onChange={(e) => {
                                setAddressLine(e.target.value.trimStart());
                            }}
                            label={lang.address}
                            value={addressLine}
                            validity={checkTextFieldValidity(
                                'address',
                                addressLine
                            )}
                            required
                        />
                        <DoubleInput>
                            <TextField
                                inputMode="numeric"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (
                                        value.length <= 4 &&
                                        value.match(ONLY_NUMBERS)
                                    ) {
                                        setZipCode(value.trimStart());
                                    }
                                }}
                                validity={
                                    checkTextFieldValidity('zip', zipCode) ??
                                    errorMessage
                                        ? {
                                              type: 'error',
                                              message: lang.invalid_zip_code,
                                          }
                                        : undefined
                                }
                                label={lang.zip_code}
                                value={zipCode}
                                required
                            />
                            <TextField
                                onChange={(e) =>
                                    setCityFromUser(e.target.value)
                                }
                                label={lang.city}
                                value={disableUserInput ? city : cityFromUser}
                                disabled={disableUserInput}
                            />
                        </DoubleInput>
                        <TermsOfUse
                            setTermsAccepted={setAcceptedTerms}
                            termsAccepted={acceptedTerms}
                        />

                        {postError && (
                            <AlertBanner
                                type="error"
                                message={lang[postError as TextKey]}
                            />
                        )}
                        <SubmitButton
                            type="submit"
                            disabled={isNotValidForm || isLoading}
                            loading={{
                                isLoading: isLoading,
                                loadingText: 'Loading',
                            }}
                        >
                            {postError === 'login_error'
                                ? lang.login
                                : lang.addNewUser}
                        </SubmitButton>
                    </Column>
                    {isNotValidForm && <p>{lang.fill_in_all_fields}</p>}
                </StyledForm>
            </div>
        </Container>
    );
};
export default CreateNewUserForm;
