import {
    breakpointDesktop,
    Column,
    Icon,
    Lozenge,
    Row,
} from '@moller/design-system';
import { ColorVariants } from '@moller/design-system/components/feedback/notifications/lozenge.css.js';
import { Link } from 'react-router-dom';
import { CarImage } from 'src/features/my-car/components/CarImage';
import Brand from 'src/types/Brand';
import Car from 'src/types/Car';
import { OrderedCar } from 'src/types/OrderedCar';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { styled } from 'styled-components';
import { useCorrectDetails } from '../../queries/useCorrectDetails';
import { CarAlert } from '../Alert';
import { CarLinkCard, Container } from '../HomeStyledComponents';
import { CarHeadline } from './CarHeadline';
import { TechnicalDetails } from './TechnicalDetails';

export interface MyCarInfo {
    readonly lozengeText: string;
    readonly lozengeVariant: ColorVariants | 'neutral';
    readonly headlineText: string;
    readonly hrefString?: string;
    readonly carType: string;
    readonly myCar?: Car;
    readonly orderedCar?: OrderedCar;
}

const StyledWebDiv = styled(Row)`
    @media screen and (min-width: ${breakpointDesktop}) {
        margin-top: var(--moller-spacing-m);
        gap: var(--moller-spacing-3xl);
        max-width: 100%;
        height: 100%;
    }
`;

const TechnicalInfo = styled(Column)`
    justify-content: center;
    margin-top: var(--moller-spacing-s);
    height: inherit;
    width: max-content;
    position: absolute;
    bottom: var(--moller-spacing-xl);
`;

const StyledColumn = styled(Column)`
    max-width: 75%;
`;

const StyledCarImage = styled(CarImage)`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transform: translateY(50%) translateX(50%);

    @media screen and (min-width: ${breakpointDesktop}) {
        transform: translateY(70%) translateX(40%) scale(1.3);
    }
`;

export const CarCard = ({
    lozengeText,
    lozengeVariant,
    headlineText,
    hrefString,
    carType,
    myCar,
    orderedCar,
}: MyCarInfo) => {
    const { technicalDetailsIcons, technicalDetailsValues, lastUpdatedMilage } =
        useCorrectDetails({ carType, myCar, orderedCar });

    const getModelAndBrand = () => {
        if (myCar) {
            return { brand: myCar.brand, model: myCar.name };
        } else if (orderedCar) {
            return { brand: orderedCar.brand, model: orderedCar.model.text };
        } else {
            return { brand: '', model: '' };
        }
    };

    const { brand, model } = getModelAndBrand();

    return (
        <Container>
            <CarLinkCard forwardedAs={Link} to={hrefString ?? ''}>
                <Row horizontalAlign="spaceBetween">
                    <StyledColumn>
                        <div>
                            <div {...ddRumPrivacySetting('mask')}>
                                <Lozenge colorVariant={lozengeVariant}>
                                    {lozengeText.toUpperCase()}
                                </Lozenge>
                            </div>
                            <CarHeadline headlineText={headlineText} />
                        </div>
                        <TechnicalInfo>
                            <StyledWebDiv>
                                {[0, 1].map((i) => (
                                    <TechnicalDetails
                                        key={i}
                                        icon={technicalDetailsIcons[i]}
                                        value={technicalDetailsValues[i]}
                                    />
                                ))}
                            </StyledWebDiv>
                        </TechnicalInfo>
                    </StyledColumn>
                </Row>
                <StyledCarImage
                    carName={model}
                    brand={brand as Brand}
                    size="small"
                />
                {lastUpdatedMilage > 100 && carType !== 'PENDING' && (
                    <CarAlert>
                        <Icon icon="info" size="s" />
                        Det er {lastUpdatedMilage} dager siden du oppdaterte
                        kilometerstand
                    </CarAlert>
                )}
            </CarLinkCard>
        </Container>
    );
};
