import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { adobeEvent } from 'adobe-utils';
import { useEffect, useState } from 'react';
import CreateNewUserForm from 'src/features/create-new-user/components/CreateNewUserForm';
import { EnrollUser } from 'src/features/create-new-user/components/EnrollUser';
import {
    postCreateUser,
    PostUserInfo,
} from 'src/features/create-new-user/utils/postCreateUser';
import { PATH_HOME } from 'src/routing/routes';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import { UserInfo } from 'src/utils/auth/mapMdmUserInfo';
import { isMobileApp } from 'src/utils/mobile';

const CreateNewUser = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [token, setToken] = useState('');
    const [postError, setPostError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [personDataResponse, setPersonDataResponse] = useState<UserInfo>({});

    useEffect(() => {
        if (isMobileApp()) {
            void Browser.close();
        }
    }, []);

    const postUser = (userData: PostUserInfo) => {
        setIsLoading(true);
        void postCreateUser(userData, token, phoneNumber, setPostError)
            .then(() => {
                setIsLoading(false);
                adobeEvent.push(
                    'newUserEvent',
                    {
                        formName: 'Create new user',
                        formId: phoneNumber,
                    },
                    Capacitor.getPlatform()
                );
            })
            .finally(() => (window.location.href = PATH_HOME));
    };

    return (
        <CenteredPageContent showBackButton>
            <div>
                {!(phoneNumber && token) ? (
                    <EnrollUser
                        phoneNumber={phoneNumber}
                        setPersonDataResponse={setPersonDataResponse}
                        setPhoneNumber={setPhoneNumber}
                        setToken={setToken}
                    />
                ) : (
                    <CreateNewUserForm
                        isLoading={isLoading}
                        result={personDataResponse}
                        postUser={postUser}
                        postError={postError}
                        login={() => (window.location.href = PATH_HOME)}
                        phoneNumber={phoneNumber}
                    />
                )}
            </div>
        </CenteredPageContent>
    );
};
export default CreateNewUser;
