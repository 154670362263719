const servicePricesForModel = {
    Volkswagen: {
        'id.3': 4937.5,
        'id.4': 5250.0,
        'id.5': 5250.0,
        'id.7': 5250.0,
        'id buzz': 6312.5,
    },
    Škoda: {
        enyaq: 5437.5, // same for enyaq coupe
        elroc: 5437.5,
    },
    Audi: {
        'q4 e-tron': 5500.0,
        'q6 e-tron': 5500.0,
        'a6 e-tron': 5500.0,
    },
    CUPRA: {
        born: 5250.0,
        tavascan: 5250.0,
    },
} as const;

export function getModelPrice(
    model: string | undefined,
    brand: string
): number | null {
    if (!model || !brand) {
        return null;
    }

    const normalizedModel = model.toLowerCase();

    if (brand in servicePricesForModel) {
        const modelsForGivenBrand =
            servicePricesForModel[brand as keyof typeof servicePricesForModel];

        for (const modelName in modelsForGivenBrand) {
            if (normalizedModel.includes(modelName.toLowerCase())) {
                return modelsForGivenBrand[
                    modelName as keyof typeof modelsForGivenBrand
                ];
            }
        }
    }

    return null;
}
