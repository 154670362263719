import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Headline,
    LinkCard,
    Lozenge,
    Row,
    Subtitle,
    breakpointMobile,
    breakpointTablet,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import energyConsumptionForModel from 'src/features/my-car/emissions-calculator/EnergyComsumptionForModel';
import { PATH_HOME, PATH_WARRANTY } from 'src/routing/routes';
import { CompetitionCard } from 'src/shared/competition-card/CompetitionCard';
import { VwCompetitionContent } from 'src/shared/competition-card/VwCompetitionContent';
import VwGenserImage from 'src/shared/competition-card/vw-genser.png';
import Brand from 'src/types/Brand';
import { useCarTransitionContext } from 'src/utils/CarTransistionContext';
import { useStickyScroll } from 'src/utils/browser/useStickyScroll';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import lang from 'src/utils/lang';
import formatLicensePlate from 'src/utils/string/formatLicensePlate';
import { selectTheme } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { StyledOverline } from '../home/components/HomeStyledComponents';
import { useCarInformation } from '../home/queries/useCarInformation';
import { useCarProfile } from '../home/queries/useCarProfile';
import { normalizeETron } from './car-brands/audi/audiUtils';
import RSLozenge from './car-brands/audi/rsLozenge.svg';
import SkodaTransition from './car-brands/skoda/SkodaTransition';
import { AnimatedLogo } from './components/AnimatedLogo';
import { CarAppStoreCard } from './components/CarAppStoreCard';
import { CarImage } from './components/CarImage';
import { CardLayout } from './components/CardLayout';
import { PageLayout } from './components/PageLayout';
import { Mileage } from './milage/Mileage';
import { useCarVideos } from './query/useCarVideos';
import { useDamageAppraisers } from './query/useDamageAppraisers';
import { Header } from './sticky-header/StickyHeader';
import { SvaService } from './sva-service-deals/SvaService';
import { TechicalDetails } from './technical-details/TechnicalDetails';
import { TireHotel } from './tireHotel/TireHotel';
import {
    getTypeNumberForVin,
    useGetSanityVideosByTypeNumber,
} from './videos/useGetSanityVideosByTypeNumber';

const ErrorContainer = styled.div`
    margin: var(--moller-spacing-m);
`;

const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--moller-spacing-m);
    margin-top: 49px;
    @media screen and (min-width: ${breakpointMobile}) {
        grid-template-columns: 1fr 1fr;
    }
`;

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin: auto;
`;

const FullWidthOnDesktop = styled.div`
    @media screen and (min-width: ${breakpointMobile}) {
        grid-column: 1 / 2 span;
    }
`;

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--moller-spacing-xs);
    align-items: flex-start;
    margin-bottom: calc(var(--moller-spacing-xl) * 2);
    @media screen and (max-width: ${breakpointTablet}) {
        min-width: 40vw;
    }
`;

const TopContent = styled.div`
    margin-bottom: var(--moller-spacing-m);
`;

export const StyledLinkCard = styled(LinkCard)<{ carBrand?: Brand }>`
    background-color: ${({ carBrand }) =>
        carBrand === Brand.VW
            ? `var(--moller-color-primary)`
            : `var(--moller-color-surface-variant)`};
    color: ${({ carBrand }) =>
        carBrand === Brand.VW
            ? `var(--moller-color-on-primary)`
            : `var(--moller-color-on-surface-variant)`};
`;

const AlignedLozenge = styled.div`
    display: flex;
    align-items: flex-start;
    margin-right: 4px;

    img {
        height: 1.1rem;
        margin-top: 7px;
    }

    @media screen and (min-width: ${breakpointTablet}) {
        img {
            height: 1.5rem;
            margin-top: 10px;
        }
    }
`;

const StyledHeadline = styled(Headline)`
    width: max-content;
    max-width: 280px;

    @media screen and (min-width: ${breakpointTablet}) {
        max-width: 450px;
    }
`;

export const MyCar = () => {
    const vin = useParams().vin;
    const navigate = useNavigate();
    const [isScrollActive, setIsScrollActive] = useState(false);
    const { shouldRunCarTransition } = useCarTransitionContext();
    const { data: technicalDetails, isLoading: tdLoading } =
        useCarInformation(vin);
    const { data: cars, isLoading: carLoading } = useCarProfile();
    const shouldUseSanityVideosForExistingCars = useFeatureToggle(
        'shouldUseSanityVideosForExistingCars'
    );

    const { data } = useCarVideos(vin);

    const typeNumber = getTypeNumberForVin(cars?.existingCars, vin);

    const { data: sanityVideos } = useGetSanityVideosByTypeNumber(
        typeNumber,
        technicalDetails?.modelYear,
        !!typeNumber &&
            !!technicalDetails?.modelYear &&
            shouldUseSanityVideosForExistingCars
    );

    const shouldLinkToVideos = shouldUseSanityVideosForExistingCars
        ? sanityVideos && sanityVideos.length > 0
        : data &&
          data.myCarMediaContentList &&
          Array.isArray(data.myCarMediaContentList) &&
          data.myCarMediaContentList.length > 0;

    const brand = technicalDetails?.brand as Brand;

    const formatCarName = (name: string | undefined | null) => {
        if (name === 'Enyaq Coupe Rs') {
            return 'Enyaq Coupé RS iV';
        }

        return name;
    };

    const carTheme = selectTheme(brand);

    const appraisers = useDamageAppraisers();
    const damageAppraisers = appraisers.data?.find((d) => d.vin === vin);
    const car = cars?.existingCars.find((c) => c.vin === vin);

    const carName = formatCarName(car?.name);

    const ShowVolkswagenCompetition = useFeatureToggle(
        'ShowVolkswagenCompetition'
    );
    const shouldShowServiceBooklet = useFeatureToggle(
        'ShouldShowServiceBooklet'
    );

    const isStickyScrollActive = useStickyScroll(
        isScrollActive,
        setIsScrollActive
    );

    const carModel = car?.name?.toLowerCase().trim();
    const isElectric = technicalDetails?.fuelType === 'Elektrisk';

    const hasEnergyConsumption = technicalDetails?.energyConsumption != null;
    const hasFallbackEnergyConsumption = energyConsumptionForModel(
        carModel ?? '',
        brand
    );

    const showEnergyConsumption =
        hasEnergyConsumption || hasFallbackEnergyConsumption != null;

    const hasPayloadData = !!(
        technicalDetails?.minimumWeight &&
        technicalDetails?.maxWeight &&
        technicalDetails.minimumWeight > 0 &&
        technicalDetails.maxWeight > 0
    );

    if (!carName || !carTheme) {
        if (carLoading) {
            return (
                <PageLayout>
                    <Headline>
                        <Skeleton />
                    </Headline>
                    <Subtitle>
                        <Skeleton />
                    </Subtitle>
                    <Skeleton height={'150px'} />
                </PageLayout>
            );
        }

        return (
            <ErrorContainer>
                <AlertBanner
                    type="error"
                    message="Kunne ikke laste inn bilen"
                    action={{
                        label: 'Tilbake til forside',
                        onClick: () => navigate(PATH_HOME),
                    }}
                />
            </ErrorContainer>
        );
    }

    const renderTransition = () => {
        if (!shouldRunCarTransition) {
            return <></>;
        }
        switch (carTheme) {
            case Brand.SKODA:
                return <SkodaTransition />;
            case Brand.VW:
                return technicalDetails?.technicalDescription === 'Varebil' ? (
                    <AnimatedLogo brand="vwNytte" />
                ) : (
                    <AnimatedLogo brand="vw" />
                );
            case Brand.AUDI:
                return <AnimatedLogo brand="audi" />;
            case Brand.CUPRA:
                return <AnimatedLogo brand="cupra" />;
            default:
                return <></>;
        }
    };

    const renderRSLozenge = () => {
        if (brand === Brand.AUDI && carName?.toLowerCase().includes('rs')) {
            return (
                <AlignedLozenge>
                    <img src={RSLozenge} alt="RS" />
                </AlignedLozenge>
            );
        }
    };

    datadogRum.setGlobalContext({
        carInfo: {
            model: carName,
            brand: brand,
        },
    });

    return (
        <ChildThemeProvider
            theme={carTheme?.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            {renderTransition()}
            {car && vin && (
                <>
                    <Header
                        car={car}
                        brand={brand}
                        isLoading={tdLoading}
                        isScrollActive={isStickyScrollActive}
                        carName={carName}
                    />
                    <PageLayout>
                        <GridLayout {...ddRumPrivacySetting('allow')}>
                            <FullWidthOnDesktop>
                                <TopContent>
                                    <SpaceBetween>
                                        <StyledColumn>
                                            <Row>
                                                {renderRSLozenge()}
                                                <StyledHeadline>
                                                    {normalizeETron(carName)}
                                                </StyledHeadline>
                                            </Row>
                                            <div
                                                {...ddRumPrivacySetting('mask')}
                                            >
                                                {!carLoading ? (
                                                    <Lozenge>
                                                        {formatLicensePlate(
                                                            car.licensePlate
                                                        )}
                                                    </Lozenge>
                                                ) : (
                                                    <Skeleton
                                                        width={'82px'}
                                                        height={'1.5rem'}
                                                    />
                                                )}
                                            </div>
                                        </StyledColumn>
                                        {carTheme !== 'bilhold' && (
                                            <CarImage
                                                carName={carName}
                                                brand={carTheme}
                                            />
                                        )}
                                    </SpaceBetween>
                                    <Mileage vin={vin} />
                                </TopContent>
                                {carTheme === Brand.VW &&
                                    ShowVolkswagenCompetition && (
                                        <>
                                            <StyledOverline>
                                                Konkurranse
                                            </StyledOverline>
                                            <CompetitionCard
                                                image={VwGenserImage}
                                                link="https://www.volkswagen.no/no/vinn-en-id7.html"
                                            >
                                                <VwCompetitionContent />
                                            </CompetitionCard>
                                        </>
                                    )}
                            </FullWidthOnDesktop>
                            {vin ? <SvaService vin={vin} /> : null}
                            <StyledLinkCard
                                carBrand={carTheme as Brand}
                                forwardedAs={Link}
                                to={`/booking?registrationNumber=${car.licensePlate}`}
                            >
                                <CardLayout
                                    icon={
                                        carTheme === 'bilhold'
                                            ? 'build'
                                            : undefined
                                    }
                                    title={lang.workshopLinkTitle}
                                    description={
                                        lang.workshopLinkDescriptionMyCar
                                    }
                                />
                            </StyledLinkCard>
                            <CarAppStoreCard
                                brand={car.brand}
                                carName={car.name}
                            />
                            <StyledLinkCard
                                carBrand={carTheme as Brand}
                                forwardedAs={Link}
                                to={`/my-car/vin/${vin}/appointments`}
                            >
                                <CardLayout
                                    icon={
                                        carTheme === 'bilhold'
                                            ? 'build'
                                            : undefined
                                    }
                                    title={lang.see_workshop_history}
                                    description={lang.workshopHistory}
                                />
                            </StyledLinkCard>
                            {shouldShowServiceBooklet && (
                                <StyledLinkCard
                                    carBrand={carTheme as Brand}
                                    forwardedAs={Link}
                                    to={`/my-car/vin/${vin}/service-history`}
                                >
                                    <CardLayout
                                        icon={
                                            carTheme === 'bilhold'
                                                ? 'build'
                                                : undefined
                                        }
                                        title={lang.seeServiceHistory}
                                        description={lang.digitalServiceBooklet}
                                    />
                                </StyledLinkCard>
                            )}
                            <StyledLinkCard
                                carBrand={carTheme as Brand}
                                forwardedAs={Link}
                                to={`${PATH_WARRANTY}/${car.brand?.toLowerCase()}`}
                            >
                                <CardLayout
                                    title={lang.car_guarantees}
                                    icon={
                                        carTheme === 'bilhold'
                                            ? 'verified'
                                            : undefined
                                    }
                                    description={lang.warranty}
                                />
                            </StyledLinkCard>
                            {hasPayloadData && (
                                <StyledLinkCard
                                    carBrand={carTheme as Brand}
                                    forwardedAs={Link}
                                    to={`/my-car/vin/${vin}/payload-calculator`}
                                >
                                    <CardLayout
                                        title={lang.payloadCalculatorTitle}
                                        icon={
                                            carTheme === 'bilhold'
                                                ? 'calculate'
                                                : undefined
                                        }
                                        description={
                                            lang.payloadCalculatorDescription
                                        }
                                    />
                                </StyledLinkCard>
                            )}
                            {isElectric && showEnergyConsumption && (
                                <StyledLinkCard
                                    carBrand={carTheme as Brand}
                                    forwardedAs={Link}
                                    to={`/my-car/vin/${vin}/emissions`}
                                >
                                    <CardLayout
                                        title={lang.co2CardTitle}
                                        icon={
                                            carTheme === 'bilhold'
                                                ? 'forest'
                                                : undefined
                                        }
                                        description={lang.co2CardDescription}
                                    />
                                </StyledLinkCard>
                            )}
                            <FullWidthOnDesktop>
                                <TechicalDetails vin={vin} />
                            </FullWidthOnDesktop>
                            {shouldLinkToVideos && (
                                <LinkCard
                                    as={Link}
                                    to={`/my-car/vin/${vin}/videos`}
                                >
                                    <CardLayout
                                        title="Bli kjent med bilen din"
                                        icon={
                                            carTheme === 'bilhold'
                                                ? 'play_circle'
                                                : undefined
                                        }
                                        description={lang.videos}
                                    />
                                </LinkCard>
                            )}
                            {damageAppraisers && (
                                <LinkCard
                                    $as={Link}
                                    to={`/my-car/vin/${vin}/damageAppraiser`}
                                >
                                    <CardLayout
                                        title={lang.find_damage_appraiser}
                                        icon={
                                            carTheme === 'bilhold'
                                                ? 'car_crash'
                                                : undefined
                                        }
                                        description={lang.damage_appraiser}
                                    />
                                </LinkCard>
                            )}
                            {vin ? (
                                <TireHotel
                                    vin={vin}
                                    isSkoda={carTheme !== 'bilhold'}
                                />
                            ) : null}
                        </GridLayout>
                    </PageLayout>
                </>
            )}
        </ChildThemeProvider>
    );
};
