import AddCarMethod from 'src/features/add-car_new/utils/AddCarMethod';
import { CarsResponse } from 'src/features/home/queries/useCarProfile';
import { PendingStatus, SystemValidation } from 'src/types/PendingCar';
import { CarStatus, MockData } from '../data';

const getCars = (mockData: MockData): CarsResponse => {
    return {
        existingCars: mockData.cars
            .filter((car) => car.CarStatus === CarStatus.EXISTING)
            .map((car) => ({
                vin: car.vin,
                licensePlate: car.licensePlate,
                name: car.model,
                brand: car.brand,
                mileage: 123456,
                registrationDate: '2014-06-19',
            })),
        pendingCars: mockData.cars
            .filter((car) => car.CarStatus === CarStatus.EXISTING)
            .map((car) => ({
                licensePlate: car.licensePlate,
                brand: car.brand,
                systemValidation: SystemValidation.VALIDATION_PENDING,
                status: PendingStatus.PENDING,
                codeExpired: false,
                method: AddCarMethod.MANUAL,
                originBilholdApi: true,
            })),
        orderedCars: [],
    };
};

export default getCars;
