import { ReasonInfos } from './types';

const reasons = {
    RELATION_ALREADY_EXISTING: {
        title: 'companyRelationAlreadyExistingTitle',
        description: 'companyRelationAlreadyExistingDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_VALID_OWNER: {
        title: 'companyProprietorNotValidTitle',
        description: 'companyProprietorNotValidOwnerDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_VALID_LEASER: {
        title: 'companyProprietorNotValidTitle',
        description: 'companyProprietorNotValidLeaserDescription',
        buttonText: 'iUnderstand',
    },
    PROPRIETOR_NOT_EXISTING: {
        title: 'companyProprietorNotExistingTitle',
        description: 'companyProprietorNotExistingDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const companyReasons: ReasonInfos<typeof reasons> = reasons;

export default companyReasons;
