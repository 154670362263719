import { ReasonInfos } from './types';

const reasons = {
    RELATION_ALREADY_EXISTING_PRIVATE: {
        title: 'ownerRelationAlreadyExistingPrivateTitle',
        description: 'ownerRelationAlreadyExistingPrivateDescription',
        buttonText: 'continue',
        continue: true,
    },
    RELATION_ALREADY_EXISTING_ORGANIZATION: {
        title: 'ownerRelationAlreadyExistingOrganizationTitle',
        description: 'ownerRelationAlreadyExistingOrganizationDescription',
        buttonText: 'continue',
        continue: true,
    },
    RELATION_ALREADY_EXISTING_ORGANIZATION_EXT: {
        title: 'ownerRelationAlreadyExistingOrganizationTitle',
        description:
            'ownerRelationAlreadyExistingOrganizationCarLeasAbleDescription',
        buttonText: 'continue',
        continue: true,
    },
    OWNER_NOT_EXISTING_IN_MVR: {
        title: 'ownerReasonOwnerNotExistingTitle',
        description: 'ownerReasonOwnerNotExistingDescription',
        buttonText: 'iUnderstand',
    },
} as const;

const ownerReasons: ReasonInfos<typeof reasons> = reasons;

export default ownerReasons;
