//#bridgecrew:skip=BC_GIT_6:<not relevant>
export default {
    logout: 'Logg ut',
    login: 'Logg inn',
    my_profile: 'Min profil',
    profile: 'Profil',
    explore: 'Utforsk',
    somethingWentWrong: 'Noe gikk galt',
    errorTryAgainLater: 'En feil har oppstått, vennligst prøv igjen senere.',
    general_error_contact_support:
        'Det har dessverre oppstått en feil. Vennligst kontakt vårt kundesenter på telefon +47 24 03 35 70 eller e-post kundeservice@moller.no.',
    deleteThreadErrorContactUsLink:
        'Det har desverre oppstått en feil og tråden kunne ikke bli slettet. Vennligst prøv igjen. Dersom problemet vedvarer kontakt oss på e-post kundeservice@moller.no.',
    postMessageErrorContactUsLink:
        'Det har desverre oppstått en feil og meldingen ble ikke sent. Vennligst prøv igjen. Dersom problemet vedvarer kontakt oss på e-post kundeservice@moller.no',
    general_error_try_again:
        'Det har dessverre oppstått en feil, vennligst prøv igjen senere. Dersom problemet vedvarer, ta kontakt med vårt kundesenter på telefon 24 03 35 70 eller e-post kundeservice@moller.no.',
    general_error_contact_support_booking:
        'Nå gikk det litt fort for oss. Vi beklager! Vi blir glade hvis du vil prøve følgende: ',
    an_error_has_occured: 'En feil har oppstått, vennligst prøv igjen.',
    register_new_user: 'Registrer deg',
    userAlreadyExistsTitle: 'Du har allerede en Bilhold-bruker!',
    userAlreadyExistsDescription:
        'Logg inn med ditt telefonnummer for å få tilgang til Bilhold.',
    remove_car: 'Fjern bilen fra Bilhold',
    back_to_main: 'Tilbake til biloversikt',
    toFrontpage: 'Til forsiden',
    find_damage_appraiser: 'Se dine skadesaker',
    damage_appraiser: 'Skadesaker',
    warranty: 'Garantier',
    see_workshop_history: 'Se tidligere verkstedbesøk',
    digitalServiceBooklet: 'Digitalt servicehefte',
    digitalServiceBookletDescription:
        'Servicehefte er et verdipapir som dokumenterer bilens gjennomførte service og oppgraderinger.',
    seeServiceHistory: 'Se servicehistorikk',
    workshopLinkTitle: 'Verkstedtjenester',
    workshopDescription: 'Service, EU-kontroll og feilsøking',
    workshopLinkDescriptionMyCar: 'Verksted',
    bookDamageAppraiserTitle: 'Gratis skadetakst',
    bookDamageAppraiserDescription: 'Tilbud i løpet av tre virkedager',
    changeImage: 'Endre bilde',
    image: 'Legg til bilde',
    uploadImageError:
        'Noe gikk galt ved opplasting av bildet. Vennligst prøv igjen.',
    deleteImageError:
        'Noe gikk galt ved sletting av bildet. Vennligst prøv igjen.',
    cookies: 'Informasjonskapsler',
    changeConsent: 'Administrer samtykker',
    termsOfService: 'Tjenestevilkår',
    account: 'Konto',
    ACCOUNTS: 'Ny bruker',
    TRAINING: 'Videoer',
    DELIVERY: 'Dokumenter',
    personalInformation: 'Personlig informasjon',
    deleteMyAccount: 'Slett min profil',
    deleteMyAccountLink:
        'Jeg vil <a href="/delete-account">slette min profil</a>',
    deleteAccountInfo:
        'Når du sletter profilen din i Bilhold vil skademeldinger og brukerdata som ligger lagret på profilen slettes.',
    deleteAllInfo:
        'Hvis du ønsker å slette ytterligere kundeinformasjon om deg hos forhandler, importør eller fabrikk, må du kontakte kundeservice på <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a>.',
    confirmDeletion: 'Bekreft sletting',
    confirmDeletionInfo:
        'Skriv inn «Slett min profil» i feltet under og klikk på knappen for å bekrefte at det er dette du ønsker.',
    confirmDeletionLabel: 'Skriv inn «Slett min profil»',
    deleteGeneralErrorTitle: 'Noe gikk galt!',
    deleteGeneralErrorDescription:
        'Det har dessverre oppstått en feil, vennligst prøv igjen senere. Dersom problemet vedvarer, ta kontakt med vårt kundesenter på e-post: kundeservice@moller.no.',
    activeValuationErrorTitle: 'Du har en aktiv skadetakst',
    activeValuationErrorDescription:
        'For å slette profilen din må du først ta kontakt med skadesenteret eller verkstedet for å avslutte skadesaken. Det kan du enkelt gjøre ved å skrive en melding i skadesaken i dine meldinger.',
    reservationDepositErrorTitle:
        'Du har en forhåndsbestilt bil med utestående depositum',
    reservationDepositErrorDescription:
        'For å slette profilen din må du først kansellere bilreservasjonen din i Bilhold.',
    reservationActiveErrorTitle: 'Du har en forhåndsbestilt bil',
    reservationActiveErrorDescription:
        'For å slette profilen din må du først ta kontakt med kundesenteret på <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> for å kansellere bilreservasjonen din.',
    contactUs: 'Kontakt oss om Bilhold',
    appStore: 'App Store',
    googlePlay: 'Google Play',
    cancel: 'Avbryt',
    finish: 'Avslutt',
    next: 'Neste',
    nextStep: 'Neste steg',
    save: 'Lagre',
    chooseDealer: 'Velg forhandler',
    draftedAppraisers: 'Utkast på skadesaker',
    appraiserInProcess: 'Aktive skadesaker',
    clickToContinue: 'Klikk for å fortsette.',
    fillOutDescription: 'Fyll ut beskrivelse',
    fillOutSchema: 'Fyll ut skjema',
    // Nye lagt til
    yes: 'Ja',
    no: 'Nei',
    mandatory: 'Obligatorisk',
    audi: 'Audi',
    vw: 'Volkswagen',
    skoda: 'Škoda',
    cupra: 'CUPRA',
    seat: 'SEAT',
    vwnytte: 'Volkswagen Nyttekjøretøy',
    skip: 'Hopp over',
    close: 'Lukk',
    future_appointment: 'Kommende avtale',
    appointmentNotEditable:
        'Det er dessverre ikke lenger mulig å endre på denne timen i Bilhold. Vennligst kontakt ditt verksted, så hjelper vi deg.',
    appointmentNotEditableBecauseNotImplemented:
        'Trenger du å endre verkstedtimen? Vennligst kontakt ditt verksted, så hjelper vi deg.',
    lastUpdatedMileage: 'Sist lagret: <b>{0} km</b>',
    add: 'Legg til',
    takePicture: 'Ta bilde',
    chooseFromLibrary: 'Velg fra bibliotek',
    workshop_title: 'Verksted',
    inspection_title: 'EU-kontroll',
    ea189_title: 'EA189',
    tirehotel_title: 'Hjulskift',
    service_title: 'Service',
    eu_inspection: 'EU-kontroll',
    date: 'Dato',
    year: 'år',
    years: 'år',
    month: 'måned',
    months: 'måneder',
    monthly: 'Månedlig',
    quarterly: 'Kvartalvis',
    halfYearly: 'Halvårlig',
    yearly: 'Årlig',
    day: 'dag',
    days: 'dager',
    technical_details_workshop: 'Tekniske data fra verkstedet',
    materials: 'Materialer',
    parts: 'Deler',
    orderServices: 'Bestill tjenester',
    order_damage_rate_online: 'Bestill skadetakst',
    updateMileage: 'Oppdater kilometerstand',
    something_went_wrong: 'Noe gikk galt. Vennligst prøv igjen.',
    error_try_again_later: 'Noe gikk galt. Vennligst prøv igjen senere.',
    car_guarantees: 'Garantier på bilen',
    car: 'bil',
    cars: 'biler',
    invalid_one_time_code:
        'Du har ikke tastet en gyldig engangskode. Tast inn 4-sifret kode fra SMS.',
    hours: 'timer',
    owner: 'Eier',
    user: 'Bruker',
    unknown: 'Ukjent',
    brand: 'Merke',
    start: 'Start',
    end: 'Slutt',
    moreInfo: 'Mer info',
    moreInfoAbout: 'Mer info om',
    hideInfo: 'Skjul info',
    hideInfoAbout: 'Skjul info om',
    read_more: 'Les mer',
    readLess: 'Les mindre',
    download: 'Last ned',
    dealer: 'Forhandler',
    details: 'Detaljer',
    invoice: 'Faktura',
    abort_booking_title: 'Sikker på at du vil avbryte bestillingen?',
    abort_booking_warning:
        'Informasjonen du har registrert vil ikke lagres dersom du forlater siden.',
    abort_booking: 'Avbryt bestilling',
    continue_booking: 'Nei, fortsett',
    // Gjennbruk gamle verdier
    workshop: 'Verksted',
    tire_hotel: 'Dekkhotell',
    service: 'verkstedstime',
    sva: 'Serviceavtale',
    email: 'E-postadresse',
    emailPending: 'E-postadresse (ubekreftet)',
    app_name: 'Mitt Bilhold',
    back: 'Tilbake',
    invalidImageUpload:
        'Bildefilen kan ikke lastes opp. Benytt et av følgende formater: .jpg, .png, .gif eller .bmp',
    service_appointment: 'Verkstedtime',
    book_service_appointment: 'Bestill verkstedtime',
    edit_service_appointment: 'Endre verkstedtime',
    call: 'Ring',
    // Gamle verdier
    toTop: 'Til toppen',
    messages: 'Kontakt',
    from: 'fra',
    requestReceivedInfo:
        'Din forespørsel om skadetakst er mottatt, og besvares senest innen tre virkedager.',
    todayTime: 'I dag',
    yesterdayTime: 'I går',
    sentDate: 'Dato for innsending',
    myMessages: 'Kontakt',
    deleteMessageThread: 'Slett meldingstråd',
    noMessagesTitle: 'Du har ingen meldinger',
    noMessagesDescription:
        'Ved forespørsel om digital skadetakst vil du kunne kommunisere direkte med skadeverkstedet om din sak her.',
    my: 'Min',
    previous: 'Forrige',
    summary: 'Oppsummering',
    confirm: 'Bekreft',
    confirmChange: 'Bekreft endring',
    other: 'Annet',
    chosen_car: 'Valgt bil',
    january: 'januar',
    february: 'februar',
    march: 'mars',
    april: 'april',
    may: 'mai',
    june: 'juni',
    july: 'juli',
    august: 'august',
    september: 'september',
    october: 'oktober',
    november: 'november',
    december: 'desember',
    sunday: 'Søndag',
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lørdag',
    edit: 'Rediger',
    remove: 'Fjern',
    open: 'åpent',
    off: 'Av',
    on: 'På',
    see: 'Se',
    name: 'Navn',
    phonenumber: 'Telefonnummer',
    change: 'Endre',
    use: 'Bruk',
    continue: 'Fortsett',
    update: 'Oppdater',
    services: 'Tjenester',
    serviceLabel: 'Tjeneste',
    price: 'Pris',
    your: 'Dine',
    damage: 'Skade',
    active: 'Aktiv',
    updateOfModel: 'Oppdatering av din {0}',
    updateIntroduction:
        'Klikk her for å lese mer om den planlagte programvareoppdateringen og forbedringene av din {0} her.',
    orderSummary: 'Ordresammendrag',
    bookingSummaryBringYourDriversLicenseIfRentalCar:
        'Ta med førerkort hvis du skal ha leiebil.',
    bookingSummaryEnsureServiceBookIsAvailable:
        'Sørg for at serviceheftet ligger i bilen dersom du ikke har dette digitalt.',
    damageAppraisers: 'Skadetakster',
    damageAppraisersFinished: 'Avsluttede skadesaker',
    damageAppraiserSent: 'Innsendt rapport',
    damageAppraiserStarted: 'Skadesak påbegynt.',
    brands: 'Merker',
    reload: 'Last inn på nytt',
    ops: 'Oops, det har skjedd en feil!',
    bookCarService: 'Bestill verkstedtime',
    damageCarsTitle: 'Velg bil',
    aboutDamage: 'Om skaden',
    damageDetailsTitle: 'Beskriv skaden',
    damagePaymentTitle: 'Opplysninger om forsikring',
    damagePaymentDescription:
        'De aller fleste skadesaker ender opp som forsikringssaker. Vi anbefaler derfor at du kontakter ditt forsikringsselskap slik at vi kan få ditt skadenummer og gjøre denne prosessen enkel for deg.',
    damagePaymentSave: 'Lage opplysninger om forsikring',
    damageDealer: 'Skadeverksted',
    damageDealerTitle: 'Velg et skadeverksted',
    damageDealerDescription:
        'Skadeverkstedet du velger vil sende deg taksten og forslag til time for reparasjon.',
    damageConfirmTitle: 'Oppsummering av din skadesak',
    damageConfirmDescription:
        'Når du sender dette mottar skadeverkstedet informasjonen om skaden og sender deg takst i Bilhold innen tre virkedager. Du vil også få forslag til time for reparasjon.',
    damageConfirmedDescription:
        'Informasjonen om din skade er sendt. Du vil få takst og forslag til time for reparasjon i Bilhold innen tre virkedager.',
    damageCar: 'Bilen det gjelder',
    yourReport: 'Din skadesak',
    seeYourReport: 'Se din skadesak',
    findYourReport:
        'Du kan alltid finne igjen saken din inne på bilens side her i Bilhold.',
    damageSentTitle: 'Informasjon om din skade er sendt!',
    damageSentDescription:
        '{0} vil nå taksere skaden og sende deg en takst og forslag til time i Bilhold innen tre virkedager.',
    damageSentInfo:
        'Når taksten er klar varsler vi deg på e-post og med push-varsel dersom du har dette aktivert.',
    damageSentEmail: 'Har vi riktig e-postadresse til deg?',
    damageDetailsDescription:
        'For å kunne gi deg en så presis takst som mulig trenger vi en beskrivelse av skaden og hvordan den oppstod. Beskriv så godt du kan i feltet nedenfor og last opp bilder av skaden.',
    damageGeneralError:
        'Vi beklager, vi klarer ikke å få kontakt med vårt takstsystem for øyeblikket. Vennligst prøv igjen senere eller ta direkte kontakt med skadeverkstedetet du vil bruke.',
    damageCompleteError:
        'Vi beklager, vi klarte ikke å sende inn din takst akkurat nå. Vennligst prøv igjen senere eller ta direkte kontakt med skadeverkstedetet du vil bruke.',
    damageGetError:
        'Vi beklager, vi klarte ikke å hente din takst akkurat nå. Vennligst prøv igjen senere eller kontakt oss på <a href="mailto:bilhold@moller.no">bilhold@moller.no</a> med en beskrivelse av problemet og ditt mobilnummer, så hjelper vi deg.',
    inputCodeDescription:
        'Oppgi koden bileier har mottatt på SMS for å legge til bilen.',
    sendNewCode: 'Send eier ny kode',
    fillInnCode: 'Legg til SMS-kode',
    confirmCode: 'Bekreft kode',
    removeRequest: 'Fjern forespørselen',
    smsCode: 'SMS-kode',
    addCar: 'Legg til ny bil',
    addCarDescription:
        'Legg til en bil du eier, leaser eller bruker slik at du kan bestille timer og få innhold tilpasset deg og din bil.',
    sendRequest: 'Send forespørsel',
    addCarWithCodeConfirm: 'Send kode til eier',
    addCarInputLabel: 'Bilens registreringsnummer',
    iOwnCar: 'Jeg eier bilen',
    leasingCar: 'Leasingbil',
    companyCar: 'Firmabil',
    iAmCarUser: 'Jeg er bruker av bilen',
    addCarSearchFormatError:
        'Registreringsnummeret skal kun bestå av 7 tegn. Eksempel: AB12345',
    addCarManuallyDescription: 'Forespørselen behandles innen 3 virkedager.',
    addCarManuallyInfo:
        'Vi klarte ikke legge til bilen din automatisk, men vil behandler forespørselen din innen 3 virkedager. Du kan likevel bestille verkstedtime eller få digital skadetakst via menyen under.',
    addCarManuallySystemErrorDescription: `Akkurat nå opplever vi feil i våre systemer, som gjør at behandlingstiden er lengre enn vanlig. Vi jobber med å rette feilen.<br/><br/> Trenger du å bestille verkstedtime med en gang?`,
    addCarWithCodeDescription:
        'For å verifisere deg som bruker sender vi eieren av bilen en engangskode på SMS som du må oppgi.',
    addCarOwnerTitle: 'Dine personopplysninger',
    addCarOwnerDescription:
        'Er opplysningene om deg korrekte? For at vi skal kunne legge til bilen må opplysningene stemme overens med folkeregisteret. Du kan oppdater opplysningene om deg selv under. Disse endringene vil bli lagret på din profil.',
    addCarOwnerEditInfo: 'Endringer vil også lagres i din profil',
    addCarUnknownBrand:
        'Vi støtter kun Audi, SEAT, CUPRA, Volkswagen og Škoda.',
    addCarPendingRequest:
        'Du har forsøkt å legge til bilen tidligere og vi behandler saken. Har det gått lang tid? Send oss en e-post til kundeservice@moller.no eller ring 24 03 35 70 så vi kan hjelpe deg.',
    addCarAlreadyAdded:
        'Denne bilen er allerede lagt til. Er den ikke synlig? Send oss en e-post til kundeservice@moller.no eller ring 24 03 35 70 så vi kan hjelpe deg.',
    addCarNotFound:
        'Registreringsnummeret finnes ikke i Motorvognregisteret enda. Prøv gjerne igjen om noen dager.',
    ownerRelationAlreadyExistingPrivateTitle: 'En annen eier av bilen finnes',
    ownerRelationAlreadyExistingPrivateDescription:
        'Det finnes allerede en annen eier av bilen i våre systemer.<br/><br/><b>Kan det hende at du er deleier?</b><br/>Velg “Jeg er bruker av bilen“ og be eier om å godkjenne.<br/><br/><b>Nylig kjøpt bruktbil?</b><br/> Det kan ta inntil 14 dager før vi får oppdatering fra Motorvognregisteret om ny eier. Prøv igjen om noen dager.<br/><br/><b>Usikker på eierskap?</b><br/> Sjekk bilens vognkort. Velg et annet alternativ og fortsett dersom du ikke er eier av bilen.<br/><br/><b>Ikke nylig kjøpt bruktbil, men du er eier?</b><br/> Du er helt sikker på at du er eier av bilen og den ikke er kjøpt nylig: fortsett under. ',
    ownerRelationAlreadyExistingOrganizationTitle:
        'En annen eier av bilen finnes',
    ownerRelationAlreadyExistingOrganizationDescription:
        'Det er et firma som står oppført som eier av bilen i våre systemer.<br/><br/><b>Nylig kjøpt bruktbil?</b><br/> Det kan ta inntil 14 dager før vi får oppdatering fra Motorvognregisteret om ny eier. Vent noen dager før du prøver igjen.<br/><br/><b>Ikke nylig kjøpt bruktbil, men du er eier?</b><br/> Du er helt sikker på at du er eier av bilen og den ikke er kjøpt nylig: fortsett under.<br/><br/><b>Er dette en firmabil?</b><br/>Velg “Firmabil“.',
    ownerRelationAlreadyExistingOrganizationCarLeasAbleDescription:
        'Det er et firma som står oppført som eier av bilen i våre systemer.<br/><br/><b>Nylig kjøpt bruktbil?</b><br/> Det kan ta inntil 14 dager før vi får oppdatering fra Motorvognregisteret om ny eier. Vent noen dager før du prøver igjen.<br/><br/><b>Ikke nylig kjøpt bruktbil, men du er eier?</b><br/> Du er helt sikker på at du er eier av bilen og den ikke er kjøpt nylig: fortsett under. <br/><br/><b>Er dette en firmabil?</b><br/>Velg “Firmabil“.<br/><br/><b>Leaser du bil?</b><br/> Hvis du leaser bilen og eier er et leasingselskap, velg “Leasingbil” og fortsett. ',
    ownerReasonOwnerNotExistingTitle: 'Det finnes ingen eier av bilen',
    ownerReasonOwnerNotExistingDescription:
        '<b>Er bilen kjøpt nylig?</b><br/> Det kan ta inntil 14 dager fra bilen ble registrert i Motorvognregisteret til vi får korrekt informasjon om eier. Vi kan først da legge til bilen. Prøv igjen om noen dager.',
    leaserRelationAlreadyExistingTitle: 'Er du sikker?',
    leaserRelationAlreadyExistingDescription:
        'Det finnes allerede en annen privatperson som leaser bilen i våre systemer. Er du helt sikker på at du er formell leasingtaker i leasingkontrakten?<br/><br/> <b>Hvis ja</b>, kan du sende vårt kundesenter leasingkontrakten, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a>. Bilen vil da legges til din profil.<br/><br/> <b>Hvis nei</b>, velg “Jeg er bruker av bilen“ og fortsett.',
    leaserProprietorNotExistingTitle:
        'Eieren av bilen finnes ikke i våre systemer',
    leaserProprietorNotExistingDescription:
        '<b>Er bilen helt ny?</b><br/> Det kan ta inntil 14 dager før vi får oppdatering fra Motorvognregisteret om korrekt eier. Prøv igjen om noen dager.<br/><br/> <b>Er bilen ikke kjøpt nylig og du har leasingkontrakten?</b><br/> Send den til vårt kundesenter, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a>, for å verifisere deg som leaser. Bilen vil da legges til din profil.<br/><br/> <b>Har du ikke leasingkontrakten?</b><br/> Leasingfirma kan kontakte en av våre forhandlere eller vårt kundesenter for å verifisere seg som eier og deg som leaser, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a>. Bilen vil så legges til din profil.',
    leaserProprietorNotValidTitle: 'Dette er nok ikke en leasingbil',
    leaserProprietorNotValidDescription:
        'Bilen er eid av en privatperson. Endre valg for å fortsette.',
    userProprietorMobileNotValidTitle: 'Eiers mobilnummer er ikke gyldig',
    userProprietorMobileNotValidDescription:
        'For at eier skal kunne godkjenne deg som bruker av bilen trenger vi eiers mobilnummer. Du kan enten be eier opprette en profil i Bilhold, eller kontakte en av våre forhandlere for å legge til sitt mobilnummer.<br/><br/> <b>Prøv på nytt når dette er gjort.</b>',
    userProprietorNotExistingTitle:
        'Eieren av bilen finnes ikke i våre systemer',
    userProprietorNotExistingDescription:
        '<b>Er bilen helt ny?</b><br/> Det kan ta inntil 14 dager før vi får oppdatering fra Motorvognregisteret om korrekt eier. Prøv igjen om noen dager.<br/><br/> <b>Er bilen eid av et firma?</b><br/> Firmaet som eier bilen kan kontakte en av våre forhandlere eller vårt kundesenter, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a>, for å verifisere seg som eier og deg som bruker av bilen. Bilen vil da legges til din profil.<br/><br/> <b>Er bilen ikke ny og eid av en privatperson?</b><br/> For at du skal kunne fortsette må eier enten opprette en bruker i Bilhold, eller kontakte en av våre forhandlere for å opprette kundeforhold. Prøv på nytt når dette er gjort.',
    userProprietorNotValidTitle: 'Kan dette være en firmabil?',
    userProprietorNotValidDescription:
        'Da bilens eier er et firma kan du prøve å velge “Firmabil” for å fortsette.',
    userLimitExceededTitle: 'Obs!',
    userLimitExceededDescription:
        'Du har sendt SMS-kode til bileier for mange ganger. Du kan sende forespørselen din til oss for manuell behandling.',
    userProprietorExcludedTitle: 'Eier må godkjenne deg som bruker av bilen',
    userProprietorExcludedDescription:
        'Eieren av bilen må godkjenne deg som bruker av bilen, men har reservert seg fra å motta SMS-koder. Eier kan kontakte en av våre forhandlere eller vårt kundesenter, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a>, for å verifisere deg. Bilen vil da legges til din profil.',
    companyRelationAlreadyExistingTitle:
        'Det finnes allerede en bruker av firmabilen',
    companyRelationAlreadyExistingDescription:
        'Det er kun mulig å ha én bruker tilknyttet en firmabil i våre systemer. Vi trenger derfor en bekreftelse fra firmaet om at du er bruker av bilen.<br/><br/> Firmaet kan kontakte en av våre forhandlere eller vårt kundesenter, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a>. Bilen vil da legges til din profil.',
    companyProprietorNotValidTitle: 'Dette er nok ikke en firmabil',
    companyProprietorNotValidOwnerDescription:
        'Bilens eier er en privatperson og ikke et firma. Endre valg for å fortsette.',
    companyProprietorNotValidLeaserDescription:
        'Det er en privatperson som leaser denne bilen, og ikke et firma. Endre valg for å fortsette.',
    companyProprietorNotExistingTitle:
        'Eieren av bilen finnes ikke i våre systemer',
    companyProprietorNotExistingDescription:
        '<b>Er bilen helt ny?</b><br/> Det kan ta inntil 14 dager før vi får oppdatering fra Motorvognregisteret om korrekt eier. Prøv igjen om noen dager.<br/><br/><b> Bilen er ikke ny?</b><br/> Firmaet som eier bilen kan kontakte en av våre forhandlere eller vårt kundesenter, <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a> for å verifisere seg som eier og deg som bruker av bilen. Bilen vil da legges til din profil.',
    defaultReasonTitle: 'Dette valget er dessverre ikke mulig å gå videre med',
    defaultReasonDescription:
        'Endre valg over for å fortsette, eller kontakt vårt kundesenter på <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a>.',
    leaserOrgNo: 'Leasingselskapets org.nr. (9 siffer)',
    companyCarOrgNo: 'Firmaets org.nr. (9 siffer)',
    orgNoGeneralError: 'Org.nr. må bestå av 9 siffer',
    findOrgNo:
        "Kan finnes i <a target='_blank' href='https://www.brreg.no/'>Brønnøysundregistrene</a>",
    companyName: 'Firmanavn',
    companyEmail: 'Din firma e-post',
    carRelationship: 'Hvilket forhold har du til bilen?',
    phoneNumber: 'Telefonnummer',
    glassDamage: 'Glasskade',
    contact: 'Kontakt',
    roadAssistance: 'Trenger du veihjelp?',
    version: 'Versjon',
    could_not_load_services:
        'En feil oppstod ved henting av tjenester. Vennligst ta kontakt med ditt verksted for å bekrefte denne timen',
    progressBarCarTitle: 'Velg bil',
    progressBarServicesTitle: 'Velg tjenester',
    progressBarAdditionalServicesTitle: 'Trenger du noe mer?',
    progressBarCalendarDescription: 'Tid reserveres i 15 minutter.',
    progressBarSummaryTitle: 'Takk for din bestilling!',
    progressBarEditedSummaryTitle: 'Din bestilling er nå endret!',
    damageCarNotRegistered: 'Er ikke bilen registrert i Bilhold?',
    damageCarInputLabel: 'Skriv inn registreringsnummer',
    damageFetchingCarError404:
        'Registreringsnummeret finnes ikke i Motorvognregisteret enda.',
    damageFetchingCarError409:
        'Vi støtter kun Volkswagen, Audi, Škoda, SEAT og CUPRA.',
    pickup_text:
        'Vanskelig å planlegge? Vi kan hente og levere bilen din hjemme hos deg eller på din arbeidsplass når du skal ha verkstedtime. Dette bestiller du under Transporttjenester på neste steg.',
    bookingNoCars:
        'For å kunne bestille verkstedtime må du legge til bilen din i Bilhold.',
    bookingOnlyPendingCars:
        'Du har lagt til bil som venter på godkjenning. For å kunne bestille verkstedtime må bilen være godkjent. Er det en annen bil du skal bestille time til?',
    delete_car_confirmation: 'Er du sikker på at du ønsker å fjerne bilen din?',
    please_wait_before_trying_again:
        'Vennligst vent 10 sekunder før du prøver på nytt.',
    send_new_code: 'Send meg ny kode',
    wrong_one_time_code: 'Feil engangskode. Vennligst prøv på nytt.',
    onlyNumbersCode: 'Koden kan kun inneholde tall.',
    fill_in_all_fields: 'Vennligst fyll ut alle feltene.',
    fill_all_fields_except_middle_name:
        'Alle feltene må være gyldige og fylt ut.',
    personal_information: 'Personopplysninger',
    one_time_password: 'Engangskode:',
    sendOneTimePassword: 'Send engangskode',
    confirmOneTimePassword: 'Bekreft engangskode',
    password: 'Passord',
    first_name: 'Fornavn',
    middle_name: 'Mellomnavn',
    last_name: 'Etternavn',
    address: 'Adresse',
    zip_code: 'Postnummer',
    city: 'Sted',
    country: 'Land',
    required: 'Må fylles ut',
    invalid_email: 'Ugyldig e-postadresse.',
    invalid_zip_code: 'Ugyldig postnummer',
    sent: 'Sendt',
    confirm_delete_report:
        'Er du sikker på at du ønsker å slette denne rapporten?',
    no_user_found: 'Finner ingen bruker tilknyttet dette nummeret.',
    go_next: 'Gå videre',
    log_out_confirm: 'Er du sikker på at du vil logge ut?',
    title: 'Bekreft din e-postadresse.',
    pendingCarInfoCode:
        'Vi har sendt en SMS-kode til bilens eier. Du må få denne koden for å legge til bilen.',
    pendingCarInfoCodePending:
        'Riktig kode oppgitt. Bilen vil være synlig innen 1 time.',
    pendingCarInfoCodeRejected:
        'Dessverre opplevde vi et problem med å aktivere bilen på din profil. Vi jobber med å få den aktivert.',
    pendingCarInfoCodeExpired:
        'Koden fra SMS er utløpt. Send på nytt til eier.',
    pendingCarExists:
        'Du har allerede lagt til denne bilen. Du kan gå til hovedsiden og bekrefte forespørselen.',
    approveCodeBadRequest: 'Feil kode. Vennligst prøv på nytt.',
    approveCodeHasExpired: 'Koden er utgått. Vennligst send ny kode til eier.',
    approveCodeTooManyRequests:
        'Vi beklager, men du har tastet koden feil for mange ganger. Be om ny kode.',
    renewCodeAwaitRetry: 'Vennligst vent 10 sekunder før du prøver på nytt.',
    renewCodeExceededDailyTries:
        'Du har sendt eier kode 2 ganger i dag, og kan ikke sende ny kode igjen før det har gått 24 t.',
    renewCodeExceededTotalTries:
        'Du kan ikke sende flere koder til eieren av denne bilen. Vennligst kontakt vårt kundesenter på <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller <a href="tel:24 03 35 70">24 03 35 70</a> så vi kan behandle din forespørsel manuelt, eller avbryt forespørselen.',
    verifyEmailByEmail:
        'Vennligst bekreft e-postadressen ved å trykke på lenken vi har sendt deg på e-post.',
    verifyPhoneNumberBySms:
        'Vennligst bekreft mobilnummeret ved å trykke på lenken vi har sendt deg på SMS.',
    verifyPhoneAndEmail:
        'Vennligst bekreft mobilnummeret ved å trykke på lenken vi har sendt deg på SMS. <br> Vennligst bekreft e-postadressen ved å trykke på lenken vi har sendt deg på e-post.',
    phone: 'Telefon',
    mail: 'Postadresse',
    VWId: 'Volkswagen ID',
    VW: 'Volkswagen',
    AUDI: 'Audi',
    SKODA: 'Škoda',
    SEAT: 'SEAT',
    images: 'Bilder',
    delete_report: 'Slett rapport',
    delete_draft: 'Slett utkast',
    lastChanged: 'Sist endret',
    damageDescription: 'Beskrivelse',
    damageDescriptionPlaceholder:
        'Eksempel: Bilen stod parkert i parkeringshus og var skrapet opp på hele høyre side av en annen ukjent bil.',
    damageDetailsValidation:
        'Du må laste opp minst ett bilde i hver kategori, og beskrivelse av skaden kan ikke være tom.',
    detailsValidationDescription: 'Beskrivelse av skaden kan ikke være tom.',
    detailsValidationImages: 'Du må laste opp minst ett bilde i hver kategori.',
    paymentValidationInsurance: 'Du må oppgi ditt forsikringsselskap.',
    paymentValidationReplacement: 'Du må oppgi om du trenger leiebil.',
    paymentValidationNumberFormat: 'Du må oppgi skadenummeret i gyldig format.',
    paymentValidation:
        'Du må oppgi ditt forsikringsselskap og om du trenger leiebil.',
    imagesOfDamage: 'Bilder av skaden',
    closeupImagesDescription:
        'Det er flott om du tar minst 5 bilder av skaden - helst fra ulike vinkler.',
    imageOverview: 'Oversiktsbilde med nummerskilt',
    overviewImagesDescription:
        'Ta et oversiktsbilde hvor du får med hele bilen, skaden og nummerskiltet.',
    mileageImagesTitle: 'Bilde av kilometerstand',
    mileageImagesDescription:
        'Dette trenger vi i tilfelle dette blir en forsikringssak.',
    isInsuranceCase: 'Er dette en forsikringssak?',
    nonValidInsuranceNumber: 'Spesialtegnene du har brukt er ikke tillat.',
    insuranceCompanyName: 'Navn på forsikringsselskap',
    yourInsuranceNumber: 'Forsikringsnummer',
    damageNumber: 'Skadenummer',
    damageNumber_: 'Skadenummer:',
    rentalCar_: 'Leiebil:',
    damageNumberDescription:
        'Vi trenger skadenummeret for å kunne taksere skaden til den avtaleprisen ditt forsikringsselskap har med oss.',
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    postalCode: 'Postnummer',
    place: 'Sted',
    cellPhoneNumber: 'Mobilnummer',
    cellPhoneNumberPending: 'Mobilnummer (ubekreftet)',
    chooseInsuranceCompany: 'Velg ditt forsikringsselskap',
    insurance: 'Forsikring',
    needsReplacementCar: 'Trenger du leiebil mens bilen repareres?',
    chooseWorkshop: 'Velg verksted',
    payment: 'Betaling',
    customerIsPaying: 'Du betaler for reparasjonen selv.',
    insuranceCompany_: 'Forsikringsselskap:',
    insuranceCompany: 'Forsikringsselskap',
    needCustomInsuranceCompany: 'Jeg fant ikke forsikringsselskapet i listen',
    customInsuranceCompanyHelpText:
        'Skriv inn navnet på ditt forsikringsselskap',
    insuranceType: 'Forsikringsavtale',
    completed: 'Ferdig!',
    'label-contractNumber': 'Kontraktsnummer',
    'label-dealerName': 'Forhandler',
    'label-queuePosition': 'Kønummer',
    'label-accountNumber': 'Kontonummer',
    'label-brand': 'Merke',
    'label-model': 'Modell',
    'label-fuelType': 'Drivstofftype',
    'label-modelYear': 'Årsmodell',
    'label-color': 'Farge',
    'label-orderNumber': 'Ordrenummer',
    contract_fuel_type_PETROL: 'Bensin',
    contract_fuel_type_DIESEL: 'Diesel',
    contract_fuel_type_ELECTRIC: 'Elektrisk',
    contract_fuel_type_FLEXIFUEL: 'Flexifuel',
    contract_fuel_type_GAS: 'Gass',
    contract_fuel_type_HYBRID: 'Hybrid',
    contract_fuel_type_PLUGIN_HYBRID: 'Plug-in hybrid',
    contract_fuel_type_UNKNOWN: 'Ukjent',
    kmFromYourPosition: '{0} km fra din posisjon',
    getReady: 'Gjør deg klar!',
    checklistTitle: 'Forbered deg til utlevering av bilen',
    checklistDescription:
        'Vi ønsker at utleveringen skal bli så bra som mulig og gir deg derfor mulighet til å forberede deg i forkant. Sjekk av for alt du gjør slik at din selger ser hvilke forberedelser du har gjort.',
    checkpointTooltip:
        'Kryss av i sjekkboksene for å informere din selger om hva du har gjort.',
    iUnderstand: 'Jeg forstår',
    privacy: 'Personvernerklæring',
    code: 'Kode',
    send: 'Send',
    regards: 'Hilsen',
    tryAgain: 'Prøv igjen',
    estimatedFinishedSleepover:
        'Takk for at vi får beholde bilen din over natten, du får <strong>{0}</strong>.',
    ok: 'OK',
    addToCalendar: 'Legg i kalender',
    editAppointment: 'Endre avtale',
    futureAppointments: 'Kommende avtaler',
    cancelAppointment: 'Avbestill time',
    cancelAppointmentConfirmation:
        'Er du sikker på at du vil avbestille verkstedtimen?',
    tryAgainLaterContactWorkshop: 'Prøv igjen senere eller ring verkstedet.',
    rememberChargingCableHeading: 'Husk å ta med ladekabel',
    rememberChargingCableDescription:
        'Sjekk av ladekabel er et av kontrollpunktene vi skal gjennomgå.',
    calendarEventIntroduction: 'Velkommen til verkstedtime hos {0}.',
    calendarEventServices: 'Du har bestilt følgende tjenester:',
    calendarEventChange:
        'Bruk vår applikasjon (iOS og Android) eller besøk Bilhold i din nettleser for å endre verkstedbestillingen: https://mittbilhold.no',
    calendarEventDuration:
        'Verkstedet tar kontakt med deg når bilen er klar for utlevering.',
    noMainServiceChosen:
        'Minst én tjeneste må være valgt for å kunne fortsette til neste steg.',
    moreServiceOptions: 'Pris: Flere alternativer',
    otherServiceSummary: 'Din problembeskrivelse',
    invalidRecommendedService:
        'For å kunne fortsette må du ta stilling til km.stand på bilen.',
    invalidOtherService:
        'For å kunne fortsette må du beskrive problemet ditt i kommentarfeltet.',
    invalidGlassService:
        'For å kunne fortsette må du fylle ut hvor stor glasskaden din er.',
    invalidRecommendedAndOtherService:
        'For å kunne fortsette må du ta stilling til km.stand på bilen og beskrive problemet ditt i kommentarfeltet.',
    invalidRecommendedAndGlassService:
        'For å kunne fortsette må du ta stilling til km.stand på bilen og fylle ut hvor stor glasskaden din er.',
    invalidOtherAndGlassService:
        'For å kunne fortsette må du fylle ut hvor stor glasskaden din er og beskrive problemet ditt i kommentarfeltet.',
    invalidRecommendedAndOtherAndGlassService:
        'For å kunne fortsette må du ta stilling til km.stand på bilen, fylle ut hvor stor glasskaden din er og beskrive problemet ditt i kommentarfeltet.',
    notCalculated: 'Ikke beregnet',
    serviceSubGroup_TIRE: 'Hjulskift / dekkhotell',
    isWorkshopFor: 'Utfører service på',
    isRetailerFor: 'Forhandler av',
    openingHours: 'Åpningstider',
    noAvailableSlotsOnline:
        'Det ser dessverre ut som om vi ikke har flere ledige timer for bestilling på nett.',
    noAvailableSlotsOnlineContact:
        'Ring oss gjerne på <strong>{0}</strong> for å høre om vi likevel kan finne en time til deg.',
    chosenWorkshop: 'Valgt verksted',
    'error-empty-comment-field':
        'Forklar det du opplever så detaljert som mulig',
    mileage: 'Kilometerstand',
    addMileageDescription:
        'For å kunne vise deg hvilke tjenester din bil trenger akkurat nå, til riktig pris, trenger vi å vite bilens kilometerstand.',
    inaccuratePriceEstimate:
        'Enkelte verkstedtjenester klarer vi ikke å beregne automatisk. Verkstedet vil derfor kontakte deg direkte med et pristilbud på arbeidet.',
    noTransportNeeded: 'Jeg trenger ingen transporttjenester',
    noTransportNeededExtended:
        'Transport til og fra verkstedet må ordnes på egenhånd.',
    sendSMSConfirmation: 'Send bekreftelse på SMS',
    sendEmailConfirmation: 'Send bekreftelse på e-post',
    remember: 'Husk',
    icalSubject: 'Innlevering av bil hos {0}',
    fallbackContactNumber: '24 03 33 00',
    mileage_cannot_be_empty: 'Kilometerstand kan ikke være tom',
    mileage_lower_than_last_saved: 'Denne er lavere enn sist, er det korrekt?',
    forceUpdateAppDialog:
        'For å kunne fortsette å bruke Bilhold er det nødvendig at du oppdaterer appen i {0}',
    fetchRecommendedServicesError:
        'Vi klarte ikke å hente anbefalte tjenester for denne bilen.',
    glassDamageTitle: 'Glasskade',
    glassDamageDescription: 'Bytte av ruter og reparasjon av glasskade',
    damageAppraiserTitle: 'Reparasjon av bilskade',
    damageAppraiserDescription:
        'Vi reparerer karosseri- og lakkskader. Få skadetakst digitalt med forslag til time for reparasjon innen tre virkedager.',
    getDigitalValuation: 'Få digital takst',
    digitalValuation: 'Digital takst',
    menuTitle: 'Meny',
    homeTitle: 'Hjem',
    dmgAppraiserTitle: 'Skadetakst',
    chooseAnotherWorkshop: 'Velg et annet verksted',
    noWorkshopSelected: 'Ingen verksted valgt enda.',
    showMoreWorkshops: 'Vis flere verksted',
    chooseTimeTitle: 'Velg tidspunkt',
    BookingConfirmTitle: 'Oppsummering',
    reviewAppTitle: 'Hvordan var opplevelsen din?',
    reviewAppDescription: 'Vurder Bilhold i {0}',
    chooseTime: 'Velg tid for innlevering av bil',
    estimatedWorkshopTimeTitle: 'Anslått hentetid',
    estimatedWorkshopDescribeProblem:
        'Vi behandler nå din bestilling. Tidspunkt og varighet for verkstedtimen avhenger av problemet du har med bilen. Verkstedet vil kontakte deg nærmere om endelig dato, tid og hentetidspunkt.',
    serviceGroupAdditionalEmpty:
        'Det finnes ingen tilleggstjenester for din bil hos valgt forhandler.',
    priceSet: 'Pris: {0} kr',
    priceNoText: '{0} kr',
    priceUndefined: 'Pris: Ikke beregnet',
    priceDefinedByWorkshop: 'Pris: Beregnes av verksted',
    definedByWorkshop: 'Beregnes av verkstedet',
    transportServices: 'Transporttjenester',
    totalPrice: 'Totalpris',
    phoneNumberWrongFormat: 'Ikke gyldig mobilnummer, må bestå av 8 tall.',
    editPhoneNumberInfo:
        'Har du nytt mobilnummer anbefaler vi at du sletter brukeren din og oppretter en ny.',
    olbErrorGetCars:
        'Beklager, vi klarer ikke å hente inn bilene dine. Vennligst prøv igjen senere eller ta direkte kontakt med verkstedet du vil booke hos.',
    noCarsDescription:
        'Her vil du kunne se dine biler og kommende avtaler. Legg til bil ovenfor for å komme i gang!',
    addNewUser: 'Opprett bruker',
    acceptPrivacy: 'Ja, jeg godtar Bilholds',
    termsOfUse: 'vilkår for bruk',
    maintenance: 'Bilhold er midlertidig utilgjengelig',
    invalidName: 'Ugyldig navn.',
    invalidAddress: 'Ugyldig addresse.',
    chassisNumber: 'Chassisnr.',
    fuelType: 'Drivstoff',
    carWeight: 'Egenvekt',
    legalMaxWeight: 'Tillat totalvekt',
    supporterNoBrakes: 'Maksvekt tilhenger u/brems',
    supporterWithBrakes: 'Maksvekt tilhenger m/brems',
    engineVolume: 'Slagvolum',
    engineEffect: 'Motorytelse/effekt',
    tiresFront: 'Dekk foran(standard)',
    tiresBack: 'Dekk bak(standard)',
    rimsFront: 'Felger foran',
    rimsBack: 'Felger bak',
    registrationYear: 'Registreringsår',
    technicalDetails: 'Tekniske data',
    videos: 'Videoer',
    noVideos: 'Fant ingen videoer for din bil.',
    subHeaderMyCarError: 'Biloversikt',
    chooseDealerTitle: 'Velg verksted',
    allWorkshops: 'Alle verksteder',
    fetchWorkshopHistoryError:
        'Vi har problemer med å hente bildata. Vennligst prøv igjen senere.',
    deleteCarError:
        'Vi har problemer med å slette bildata. Vennligst prøv igjen senere.',
    deleteDamageAppraiserError:
        'Vi klarte ikke slette denne rapporten. Vennligst prøv igjen senere.',
    fetchServiceError:
        'Vi har problemer med å hente dine dekkhotell- og serviceavtaler. Vennligst prøv igjen senere.',
    searchInputDealerPlaceholder: 'Verksted, sted',
    sortWorkshopsLabel: 'Viser verksted basert på',
    searchWorkshopsLabel: 'Søk og finn verksted',
    noSearchResults: 'Ingen treff.',
    noSearchResultsHint: 'Prøv en annen stavemåte.',
    position: 'Posisjon',
    login_error: 'Vi klarte ikke å logge deg inn. Vennligst prøv igjen.',
    myCar: 'Min bil',
    myCarNoRelationTitle: 'Bilen er ikke lagt til i Bilhold',
    myCarNoRelationMessage:
        'For å vise flere detaljer, må vi vite mer om ditt forhold til bilen.',
    myCarNoRelationAddCar: 'Klikk her for å legge til bilen i Bilhold.',
    myCarPendingTitle: 'Bilen er under behandling',
    myCarPendingMessage:
        'Du har lagt til bilen i Bilhold, og forespørselen behandles. Du får se flere detaljer når forespørselen blir godkjent. Behandlingen tar inntil 3 virkedager.<br/></br>Har du ventet mer enn 3 virkedager? Ta kontakt med <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a> eller ring <a href="tel:+4724033570">24 03 35 70</a>, så kan vi hjelpe deg.',
    showAll: 'Vis alle',
    hideAll: 'Skjul',
    workshopHistory: 'Verkstedhistorikk',
    workDone: 'Utført arbeid',
    noWorkshopHistoryForCar:
        'Finner ingen registrert verkstedhistorikk på bilen.',
    noWorkshopHistoryForCarSystemError:
        'Du kan ta kontakt med verkstedet ditt hvis du trenger mer informasjon om verkstedshistorikken til bilen.',
    noInvoiceFromPreviousHistory:
        'Fakturaspesifikasjon vises ikke på historikk fra tidligere eier',
    accountNumberHelpText: 'Vennligst benytt kontonummer som ikke krever KID.',
    myDealer: 'Min forhandler',
    myOrderedCarHeader: 'Din nye bil er bestilt!',
    myOrderedCarCheckListDesc:
        'Vi har laget en sjekkliste med nyttige forberedelser. Din selger{0}vil se hva du har vært gjennom, og skreddersy utlevering ut fra dette.',
    openChecklist: 'Åpne sjekklisten',
    shopOpensTitle: 'Fra og med <span>{0}</span> kan du bestille din nye bil!',
    shopOpensDescription:
        'Nettbutikken er nå åpen og du vil få tilgang til å logge inn, bygge og bestille din nye bil når det er din tur - basert på ditt kønummer.',
    shopOpensWithoutOrderDate: 'Du vil snart kunne bestille din bil!',
    shopsOpenButton: 'Gå til nettbutikk',
    cancelReservationTitle: 'Kansellere din forhåndsbestilling?',
    cancelReservationDescription:
        'Husk at du kan vente og tenke deg om helt til frem til vi åpner for bestilling.',
    cancelReservationNoRegrets:
        'Det er ikke mulig å angre dersom du nå bekrefter kansellering av din forhåndsbestilling.',
    cancelReservationRefund: 'Tilbakebetaling',
    cancelReservationWhyYouCancel:
        '  For at vi i fremtiden skal kunne yte best mulig service, ønsker vi å vite hvorfor du velger å kansellere.',
    feedback: 'Tilbakemelding',
    cancelReservationRefundDescription:
        'Vennligst fyll inn ønsket kontonummer som beløpet skal tilbakeføres til. Beløpet vil normalt være tilbakeført på din konto innen 5 virkedager.',
    cancelReservation: 'Kanseller din forhåndsbestilling',
    cancelReservationCancelCompleted: 'Kanselleringen er gjennomført',
    invalidBankAccountNumber: 'Ugyldig kontonummer',
    tireHotelDeal: 'Dekkhotell',
    tireHotelDealDealer: 'Dine dekk er sjekket inn hos <strong>{0}<strong>',
    svaDealTitle: 'Service- og vedlikeholdsavtale',
    serviceDealTitle: 'Serviceavtale',
    serviceDealSubTitleSuspended:
        'Din avtale er midlertidig stoppet grunnet <strong>{0}</strong>',
    serviceDealContentInfo: 'Basert på sist oppdaterte kilometerstand',
    customerRentalCar: ' Erstatningsbil er inkludert i avtalen din',
    remainingServiceDeal:
        '<strong>{0} km</strong> eller <strong>{1} måneder</strong> gjenstår',
    remainingServiceDealUsedCar: '<strong>{0} måneder</strong> gjenstår',
    noCustomerRentalCar: 'Erstatningsbil er ikke inkludert i avtalen din',
    customerPricePerMonth: 'Avtalt pris ekskl. mva per måned: {0} NOK',
    dealReadMore: 'Les mer om avtalen',
    aToAA: 'A-Å',
    bookingCalendarSlotTakenError:
        'Vi beklager, men det valgte tidspunktet har blitt reservert av noen andre.',
    bookingCalendarRefresh: 'Last kalender på nytt',
    bookingConfirmationError:
        "Vi beklager, men lenken fra e-posten har blitt benyttet for mange ganger og fungerer ikke lenger. Du kan logge deg inn <a href='/home'>her</a> og velge tid for time i vår kalender.",
    bookingConfirmationIdInvalid:
        'Vi fant dessverre ikke invitasjonen din. Vennligst prøv igjen senere eller ta direkte kontakt med verkstedet du vil booke hos.',
    noServicesFound:
        'Vi fant dessverre ingen tjenester som samsvarer med valgt bil og forhandler',
    refundTitle: 'Penger tilbake',
    selfServiceInfoTitle: 'Spar tid og lever bilen når det passer deg!',
    selfServiceInfoOne:
        'Du kan levere og hente bilnøklene i en av våre selvbetjeningsautomater',
    selfServiceInfoTwo:
        'Vi har automater både <b>utendørs</b> og <b>innendørs</b>',
    selfServiceInfoThree:
        'Du kan levere <b>utenfor åpningstid</b> ved bruk av utendørsautomaten',
    selfServiceInfoFour:
        'Eventuell betaling kan gjøres med bruk av kort eller Vipps',
    refundDescription:
        'Få pengene du betalte for å forhåndsreservere bil tilbakebetalt.',
    accountNumberReceived: 'Kontonummeret er mottatt',
    moneyTransferPeriod:
        'Beløpet vil normalt være tilbakeført på din konto innen 5 virkedager.',
    refundActionTitle: 'Be om tilbakebetaling',
    createUserDescriptionText:
        'Registrer deg og få tilgang til din bil, bestilling av verkstedtime og skadereparasjon.',
    myConsents: 'Mine samtykker',
    saveAsFavourite: 'Lagre som favoritt',
    savedAsFavourite: 'Lagret som favoritt',
    postPreferredDealerError:
        'Vi klarte dessverre ikke å lagre verkstedet som din favoritt. Vennligst prøv igjen senere.',
    deletePreferredDealerError:
        'Vi klarte dessverre ikke å fjerne verkstedet som din favoritt. Vennligst prøv igjen senere.',
    preferredDealer: 'Foretrukket forhandler',
    sendMessage: 'Send en melding',
    provideMoreInfo: 'Bruk meldingsfeltet for å gi oss mer info',
    seeDamage: 'Se hele skadesaken her',
    deleteMessageTitle:
        'Er du sikker på at du vil slette denne meldingstråden?',
    deleteMessageDesc: 'Denne handlingen kan du ikke gjøre om på.',
    delteMessageConfirmButton: 'Slett meldingstråd',
    beginTitle: 'Digitalt verksted rett i lomma',
    beginDescription:
        'I Bilhold finner du alt du trenger for å holde din Audi, CUPRA, SEAT, Škoda eller Volkswagen i god stand — akkurat når det passer deg best.',
    beginAssistance: 'Jeg trenger umiddelbar assistanse',
    EuControlSoon: 'Bestill EU-kontroll',
    DlEuControl: 'Frist {0}',
    OrderWithUs:
        'Det er {0} til frist for din EU-kontroll. Les alt om inspeksjonen her og bestill time nå.',
    inspectionServiceSoon: 'Snart på tide med service!',
    orderService: 'Bestill service',
    orderTireChangeAppointmentTitle: 'Hjulskift',
    orderTireChangeAppointmentDescription: 'Bestill hjulskift og dekkhotell',
    orderTireChange: 'Bestill hjulskift',
    orderTireHotel: 'Bestill dekkhotell',
    orderInspectionWithUs:
        'Bilen din er snart to år og da nærmer det seg tid for service. Vær ute i god tid og bestill time i dag.',
    footerDescription:
        'Bilhold er en digital tjeneste for deg som eier en Audi, CUPRA, SEAT, Škoda eller Volkswagen. Tjenesten er levert av Harald A. Møller AS. ',
    allRights: 'Harald A. Møller AS. Alle rettigheter.',
    availability: 'Tilgjengelighetserklæring',
    usefulInformation: 'Nyttig informasjon',
    downloadApp: 'Last ned appen',
    haraldAMollerOrgNr: 'Org.nr 943 733 988',
    customerServiceMail: 'kundeservice@moller.no',
    phoneToCustomerService: 'Telefon:',
    openInMaps: 'Åpne i Google Maps',
    appointmentError:
        'Vi finner ingen detaljer om denne avtalen. Prøv igjen senere, eller ring din forhandler.',
    appointmentTime: 'Tidspunkt',
    delaysDueToNewSystems:
        'Vi går for tiden over på nye interne systemer. Vi har derfor begrenset kapasitet og innkjøring av nye rutiner vil kunne føre til at ting tar litt lenger tid enn vanlig. Takk for tålmodigheten!',
    deletingCar: 'Fjerner bilen. Vennligst vent',
    updateMileageError:
        'Vi klarte dessverre ikke å oppdatere kilometerstanden. Prøv igjen senere.',
    noAppoinementsMessage:
        'Du har ingen kommende avtaler eller oppgaver for denne bilen. Kjør forsiktig!',
    valuationCardTitle: 'Ønsker du å selge bilen din?',
    commonPayloadTitle: 'Nyttelast for elbil',
    payloadTitlePlanning:
        'Det finnes regler for hvor mye last bilen din kan ha.',
    payloadDescriptionPlanning:
        'Tillat lastekapasitet er ofte lavere på en elbil enn tilsvarende bil med forbrenningsmotor. Hva betyr det?',
    payloadLinkText: 'Test vår nyttelastkalkulator!',
    co2CardTitle: 'Hvor mye sparer du miljøet med elbil?',
    co2CardDescription: 'Miljø',
    valuationCardBody:
        'Det er enkelt, stressfritt og vi overtar hele ansvaret når du selger bilen til oss.',
    requestValuation: 'Få en verdivurdering',
    npsQuestion:
        'Hvor sannsynlig er det at du anbefaler Bilhold til en venn eller kollega?',
    npsThankYou: 'Takk for tilbakemelding!',
    npsNotLikely: 'Lite sannsynlig',
    npsVeryLikely: 'Veldig sannsynlig',
    addCarBeforePreferredDealer:
        'Du må først legge til en bil i profilen for å kunne velge foretrukket forhandler.',
    settings: 'Innstillinger',
    frontpage: 'Forside',
    newBookingSystem: 'Ny bookingløsing',
    newBookingSystemDescription:
        'Denne forhandleren bruker en ny bookingløsning som du vil bli sendt videre til.',
    payloadCalculatorTitle: 'Hvor mye kan du laste i bilen?',
    payloadCalculatorDescription: 'Nyttelastkalkulator',
    noChosenWeight: 'Ingen valgt vekt',
    buyUsedCarCardTitle: 'Ser du etter ny bruktbil?',
    buyUsedCarCardBody:
        'Vi har et stort utvalg av nyere brukte personbiler og nyttekjøretøy til salgs til en fornuftig pris.',
    buyUsedCarButtonText: 'Finn en ny bruktbil',
    carAgeByMonth: 'mnd. gammel bil',
    noDataServiceBooklet:
        'Vi finner dessverre ikke noe data for ditt digitale servicehefte!',
    errorServiceBooklet: 'Vi klarte ikke å hente ditt digitale servicehefte',
    wsHistoryGuideTitle: 'Har du kjøpt denne bilen brukt?',
    wsHistoryGuideDescription:
        'Av personvernhensyn kan vi ikke dele verkstedhistorikk fra tidligere eiere uten deres samtykke.',
    wsHistoryGuideAccordionTitle: 'Slik får du historikken',
    wsHistoryGuideAccordionTextA:
        'Kontakt tidligere eier for å innhente fullmakt. Hvis du ikke vet hvem som eier bilen, kan du finne informasjon på Statens Vegvesen:',
    wsHistoryGuideAccordionTextB:
        'Verkstedet vil kreve en attest som dokumenterer eierforholdet.',
    wsHistoryGuideAccordionTextC:
        'Du eller tidligere eier må deretter kontakte verkstedet med en fullmakt for å dele verkstedhistorikken. Du kan få historikken tilsendt på e-post eller hente den hos forhandler. Fakturaer fra tidligere eiere deles ikke på grunn av personvern.',
    feedbackTitle: 'Er denne informasjonen nyttig for deg?',
    feedbackContent:
        'Fortell oss når du trenger informasjonen, hva du savner og hvordan vi kan gjøre siden mer nyttig for deg.',
};
