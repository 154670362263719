import Brand from 'src/types/Brand';

export const selectTheme = (brand: string | undefined | null) => {
    if (brand === Brand.SKODA) {
        return Brand.SKODA;
    }
    if (brand === Brand.NYTTE) {
        return Brand.NYTTE;
    }
    if (brand === Brand.VW) {
        return Brand.VW;
    }
    if (brand === Brand.AUDI) {
        return Brand.AUDI;
    }
    if (brand === Brand.CUPRA) {
        return Brand.CUPRA;
    }

    return 'bilhold';
};
export type Themes =
    | Brand.AUDI
    | Brand.VW
    | Brand.SKODA
    | Brand.NYTTE
    | Brand.CUPRA
    | 'bilhold';
